.user-search-field-cont {
  position: relative;
  margin-bottom: 10px;
}

.user-search-field-cont .row-1 {
  position: relative;
  max-height: 75px;
}

.user-search-field-cont .row-1 > div {
  position: absolute;
  top: 65px;
  border-radius: 10px;
  z-index: 99999;
  width: 100%;
}

.user-search-field-cont .row-1.active > div {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.1);
}

.user-search-field-cont input.search-input {
  background: #ffffff;
  border: 1px solid rgba(19, 15, 64, 0.2);
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 9px 15px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #130f40;
}

.user-search-field-cont input.search-input::placeholder {
  opacity: 0.4;
}
.user-search-field-cont label {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #5a577a;
}

.searched-user-container {
  transition: all 0.5s;
  border-top: 0;
  box-sizing: border-box;
  max-height: 297px;
  overflow: auto;
  margin-top: 5px;
}

.searched-user-row {
  padding: 10px;
  border-radius: 10px;
}

.searched-user-row.show {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  background: #ffffff !important;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.1) !important;
  box-sizing: border-box;
}

.searched-user-row.show:hover {
  background: #ffffff !important;
}

.searched-user-row:hover {
  background: #e8e7ec;
}

.searched-user-row.selected:hover {
  background: transparent;
}
.searched-user-row:last-child {
  margin-bottom: 0px;
}

.searched-user-row > div:nth-child(1) {
  width: 50px;
}
.searched-user-row > div:nth-child(2) {
  width: calc(100% - 100px);
}
.searched-user-row > div:nth-child(3) {
  width: 25px;
}

.searched-user-row > div:nth-child(3) > img {
  width: 100%;
}

.searched-user-row .title {
  font-weight: 500;
  font-size: 22px;
  /* line-height: 27px; */
  color: #130f40;
  margin: 0;
}

.searched-user-row .sub-title {
  font-weight: 500;
  font-size: 14px;
  /* line-height: 17px; */
  color: #8987a0;
  margin: 0;
}

.searched-user-row .tag {
  padding: 2px 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: #e8faef;
  border-radius: 10px;
  color: #17dc69;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.searched-user-row .tag.new {
  background-color: #fee6e6;
  color: #f50303;
}

.searched-user-row .avatar {
  height: 50px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.searched-user-row .avatar img,
.searched-user-row .avatar > span {
  height: 100%;
  display: block;
}
