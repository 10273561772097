.inv-dashboard-page * {
  box-sizing: border-box;
}
.inv-dashboard-page {
  width: 1170px;
  margin: 0 auto;
}

.inv-db-chart {
  background-color: rgba(19, 15, 64, 1);
  border-radius: 20px;
  height: 324px;
  padding: 10px;
}

/* summary */
.summary-overview-cont > div {
  margin-bottom: 15px;
}

.summary-overview-cont > div:last-child {
  margin-bottom: 0;
}

.summary-overview-cont > div > a {
  width: calc((100% - 45px) / 4);
  background: #fff;
  color: var(--primary);
  box-shadow: 0px 0px 50px 0px rgba(12, 25, 40, 0.05);
  border-radius: 15px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 20px 20px 20px;
  text-decoration: none;
}

.summary-overview-cont > div > a:hover {
  background-color: #130f401a;
  cursor: pointer;
}

.summary-overview-cont > div > a.v-hide {
  visibility: hidden;
}

.summary-overview-cont .summary_header {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--primary-300);
}

.summary-overview-cont .summary_title {
  color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  /* opacity: 0.7; */
  margin: 0;
}

.summary-overview-cont .summary_currency {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
  margin: 0;
}

.summary-overview-cont .info > div {
  height: 21px;
  margin-bottom: 5px;
}

.summary-overview-cont .info > div:last-child {
  margin-bottom: 0;
}

.summary-overview-cont .info > div:last-child span:first-child {
  opacity: 1;
  font-weight: 500;
}

.summary-overview-cont .info > div span {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
