.page-cont.bm-config-page {
  max-width: 1170px;
}

/* common */

.bm-config-page .section-header {
  margin-bottom: 10px;
}

.bm-config-page .section-header .section-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--primary-700);
  margin: 0;
}

.bm-config-page .section-header .reload-button {
  width: 35px;
  height: 35px;
  margin: 0 10px;
  /* margin-left: 10px; */
}

.bm-config-page .section-header .reload-button.disabled {
  filter: grayscale(100%);
  pointer-events: none;
  cursor: default;
}

.bm-config-page .section-header .reload-button > button {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: block;
}
.bm-config-page .section-header .reload-button > button > img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: -0.5px;
  left: 0;
}
.bm-config-page .section-header .reload-button > button > img:first-of-type {
  opacity: 1;
}
.bm-config-page
  .section-header
  .reload-button:hover
  > button
  > img:first-of-type {
  opacity: 0;
}
.bm-config-page .section-header .reload-button > button > img:last-of-type {
  opacity: 0;
}
.bm-config-page
  .section-header
  .reload-button:hover
  > button
  > img:last-of-type {
  opacity: 1;
}

.bm-config-page
  .section-header
  .reload-button.disabled:hover
  > button
  > img:first-of-type {
  opacity: 1;
}

.bm-config-page
  .section-header
  .reload-button.disabled:hover
  > button
  > img:last-of-type {
  opacity: 0;
}
/* common */

/* header */
.bm-config-page .page-header-cont {
  border-bottom: 1px solid rgba(19, 15, 64, 0.1);
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}

.bm-config-page .page-header-cont ul {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.bm-config-page .page-header-cont ul li {
  list-style-type: none;
  color: var(--secondary);
  font-size: 14px;
  margin-right: 30px;
  border-bottom: 1px solid transparent;
}
.bm-config-page .page-header-cont ul li:hover,
.bm-config-page .page-header-cont ul li.active {
  border-bottom: 1px solid var(--secondary);
}

.fb-page-info-cont {
  background-color: #130f401a;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 40px;
}

.fb-page-info-cont .page-title,
.fb-page-info-cont .page-subtitle {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-logo {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-right: 15px;
}

.page-logo img,
.page-logo > span {
  height: 100%;
  width: 100%;
  display: block;
}

.fb-page-info-cont > div:nth-child(1) {
  width: 100%;
}

.fb-page-info-cont .page-count {
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
  margin: 0;
  margin-right: 30px;
  white-space: nowrap;
}

.fb-page-info-cont .page-title {
  font-size: 22px;
  line-height: 27px;
  color: var(--primary);
  margin: 0;
  margin-bottom: 3px;
  white-space: nowrap;
}

.fb-page-info-cont .page-id {
  font-size: 14px;
  line-height: 17px;
  color: #8987a0;
  margin: 0;
}

/* page sync table */
.page-info-sync-table {
  margin-bottom: 30px;
}

.page-info-sync-table .snippet-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 21px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary);
}

.page-info-sync-table tr.no-data {
  border: none;
}

.page-info-sync-table tr.no-data:hover {
  background: none !important;
  border: none !important;
}

.page-info-sync-table td > span > .button-solid_green {
  height: 30px;
}

.page-info-sync-table tfoot tr td {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.page-info-sync-table tfoot tr {
  height: 60px;
}

.page-info-sync-table tfoot tr button {
  height: 30px;
}

.page-info-sync-table tfoot tr td {
  padding-right: 15px;
  padding-left: 20px;
}

.page-info-sync-table tfoot tr .right-col button {
  margin-left: 5px;
}

/* messages sync */
.page-info-sync-table .message-sync-cont {
  width: 85%;
}

.page-info-sync-table .message-sync-cont > :nth-child(1) {
  max-width: 250px;
}

.page-info-sync-table .message-sync-cont > :nth-child(2) {
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.page-info-sync-table .message-sync-cont > :nth-child(2).active {
  border: 1px solid yellowgreen;
}

.page-info-sync-table .message-sync-cont > :nth-child(3) {
  width: calc(100% - 230px - 30px);
}

.page-info-sync-table .message-sync-cont h3 {
  font-size: 16px;
  color: var(--primary);
  margin: 0;
}

.page-info-sync-table .message-sync-cont p {
  font-size: 14px;
  color: var(--primary-700);
  margin: 0;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* add page modal */
.add-page-modal-cont {
  padding: 20px;
}
.add-page-modal-cont > div:first-child {
  margin-bottom: 25px;
}

.add-page-modal-cont > div:last-child > button {
  width: 120px;
  height: 40px;
}
