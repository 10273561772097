.date-range-cont {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 275px;
  width: 275px;
  height: 39px;
  border: 1px solid #d0cfd9;
  background-color: #f3f3f5;
  border-radius: 8px;
}

.date-range-cont input {
  width: calc(100% - 35px);
  height: 100%;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 1px solid #d0cfd9;
  outline: none;
  padding: 0px 15px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #130f40;
  display: block;
}

.date-range-cont .dot-menu {
  position: relative;
  width: 35px;
  height: 100%;
  border-radius: 8px;
}
.date-range-cont .dot-menu > img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

/* inst option popup */
.date-range-cont .inst-option-cont::before {
  right: 11px;
}

.date-range-cont .inst-option-cont {
  top: 55px;
}
