.page-header-cont {
  height: 80px;
}

.page-header-cont .page-header_title {
  color: var(--primary);
  font-size: 30px;
  font-weight: 500;
  margin: 0;
}

.page-header-cont .page-header_search > input[type="text"] {
  width: 300px;
}

.reload-button {
  width: 40px;
  height: 40px;
}

.reload-button.mr-2 {
  margin-right: 10px;
}

.reload-button > button {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.reload-button > button > img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: -0.5px;
  left: 0;
}
.reload-button > button > img:first-of-type {
  opacity: 1;
}
.reload-button:hover > button > img:first-of-type {
  opacity: 0;
}
.reload-button > button > img:last-of-type {
  opacity: 0;
}
.reload-button:hover > button > img:last-of-type {
  opacity: 1;
}

/* inst option popup */
.page-header-cont .inst-option-cont {
  top: 57px;
}
