.inst-option-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
  position: absolute;
  top: 50px;
  right: -14px;
  width: 150px;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 12px 2px #e6e6e6;
  padding: 8px !important;
  z-index: 10;
}

.inst-option-cont.v2 {
  position: fixed;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
}

.inst-option-cont.v2::before {
  left: 15px;
}

.inst-option-cont::before {
  content: url("./../images/icons/icon-corner.svg");
  width: 40px;
  height: 30px;
  position: absolute;
  top: -16px;
  right: 8px;
  z-index: 1;
}
.inst-option-cont > button {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  z-index: 9999999;
  background: #fff;
  padding-left: 6px;
}
.inst-option-cont > button:hover {
  background: rgba(19, 15, 64, 0.05);
}
