.page-cont.conversation-page {
  max-width: 1170px;
}

.conversation-container * {
  box-sizing: border-box;
}

.conversation-container > div {
  border-radius: 15px;
  /* background: #fff; */
  box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05);
  /* padding: 15px; */
  height: 100vh;
  overflow: hidden;
  /* background-color: yellowgreen; */
}

.conversation-container > div:first-child {
  width: calc(100% - 320px);
}

.conversation-container > div:last-child {
  width: 300px;
}

/* left column */
.con_left-col > div {
  /* border-radius: 15px; */
  background: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05);
  /* padding: 15px; */
  height: 100%;
  overflow: auto;
  /* background-color: yellow; */
}

.con_left-col > div:first-child {
  width: 300px;
  background-color: #ffffff;
  border-right: 1px solid rgba(28, 38, 73, 0.2);
}

.con_left-col > div:last-child {
  width: calc(100% - 301px);
  /* background-color: aquamarine; */
}

/* left-col chat column */
.con_chat-cont {
  height: 100%;
}

.con_chat-header {
  height: 70px;
  padding: 15px;
  border-bottom: 1px solid rgba(28, 38, 73, 0.2);
  /* margin: 0 -15px; */
}

.con_chat-header select {
  padding-bottom: 5px !important;
}

/* .con_chat-user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
} */

.con_chat-list {
  padding: 15px;
  height: 100%;
}

.con_chat-list > div {
  margin: 0 -5px;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.con_chat-list > div:hover,
.con_chat-list > div.active {
  background: rgba(19, 15, 64, 0.05);
}

.con_chat-list > div:last-child {
  margin-bottom: 0px;
}

.con_chat-user-avatar > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
}

.con_chat-user-name {
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.con_user-chat {
  color: var(--primary-500);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.con_chat-day {
  color: var(--primary-500);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-left: 10px;
}

/* left-col conversation column */
.con_conversation-header {
  /* padding: 20px 15px 20px 15px; */
  height: 70px;
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(28, 38, 73, 0.2);
}

.con_conversation-user-name {
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.con_conversation-user-avatar > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.con_conversation-action {
  width: 35px;
  height: 35px;
  background: rgba(19, 15, 64, 0.08);
  border-radius: 6px;
  margin-left: 10px;
}

.con_conversation-action > img {
  width: 55%;
}

.con_conversation-body {
  padding: 5px 10px;
  overflow: auto;
  height: 700px;
}

.con_conversation-thread {
  padding: 15px;
  border-radius: 8px;
  background: #fef5e9;
  margin-bottom: 5px;
}

.con_conversation-thread:first-child {
  border-radius: 8px 8px 8px 0px;
}
.con_conversation-thread:last-child {
  border-radius: 0px 8px 8px 8px;
}

.con_conversation-thread > p {
  color: var(--primary-700);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}
