/* common */
button.no-style {
  border: 0px solid;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.button {
  border: unset;
  outline: unset;
  background: unset;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

button.button:disabled {
  filter: grayscale(100%);
  cursor: default;
}

button.button:not(:disabled)::after {
  position: absolute;
  content: "";
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e8e7ec38;
  transition: all 0.3s ease-in-out;
}

button.button:not(:disabled):hover::after {
  top: 0;
}

button.button-menu:not(:disabled):hover::after {
  display: none !important;
}

.button.fill-x {
  width: 100%;
}

.button.fill-y {
  height: 100%;
}

/* green */
.button-line_green {
  background: #e8faef;
  color: #17dc69;
  padding: 7px 15px;
  border-radius: 8px;
}

.button-solid_green,
.button-line_green:hover {
  background: #17dc69;
  color: #fff;
  padding: 7px 15px;
  border-radius: 8px;
}

/* pink line */
.button-line_pink {
  background: rgba(245, 0, 87, 0.1);
  color: #f50057;
  padding: 7px 15px;
  border-radius: 8px;
}

/* purple */
.button-line_purple {
  background: #f7e6f8;
  color: #ae00b9;
  padding: 7px 15px;
  border-radius: 8px;
}

.button-solid_purple,
.button-line_purple:hover {
  background: #ae00b9;
  color: #fff;
  padding: 7px 15px;
  border-radius: 8px;
}

/* gray */
.button-solid_gray {
  background: #f3f3f5;
  color: #8987a0;
  padding: 7px 15px;
  border-radius: 8px;
}

/* sky */
.button-line_sky {
  background: #dcf5ff;
  color: #14b9ff;
  padding: 7px 15px;
  border-radius: 8px;
}

.button-solid_sky,
.button-line_sky:hover {
  background: #14b9ff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 8px;
}

/* red */
.button-line_red {
  background: #fee6e6;
  color: #f50303;
  padding: 7px 15px;
  border-radius: 8px;
}

.button-solid_red,
.button-line_red:hover {
  background: #f50303;
  color: #fff;
  padding: 7px 15px;
  border-radius: 8px;
}

/* orange */
.button-line_orange {
  background: #fff1dc;
  color: #ffa113;
  padding: 7px 15px;
  border-radius: 8px;
}

.button-solid_orange,
.button-line_orange:hover {
  background: #ffa113;
  color: #fff;
  padding: 7px 15px;
  border-radius: 8px;
}

/* dot menu */
.button-menu-cont {
  position: relative;
}

.button-menu-cont .button-menu {
  width: 30px;
  height: 30px;
  padding: 0;
}

.button-menu-cont .button-menu > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* reload button */
.button-reload {
  /* width: 100%;
  height: 100%; */
  width: 40px;
  height: 40px;
}

.button-reload.ml-2 {
  margin-left: 10px;
}

.button-reload.mr-2 {
  margin-right: 10px;
}

.button-reload > button {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.button-reload > button > img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: -0.5px;
  left: 0;
}
.button-reload > button > img:first-of-type {
  opacity: 1;
}
.button-reload:hover > button > img:first-of-type {
  opacity: 0;
}
.button-reload > button > img:last-of-type {
  opacity: 0;
}
.button-reload:hover > button > img:last-of-type {
  opacity: 1;
}
