/* overview-col-1 */
.overview-col-1 > div {
  width: 100%;
}

/* overview-col-1 > row-2 */
.overview-col-1 > .row-2 {
  height: calc(100% - 90px);
  /* border-radius: 10px;
  background: var(--primary-50); */
}

.overview-col-1 > .row-2 > div {
  height: 100%;
}

.overview-col-1 > .row-2 > div:first-child {
  width: calc(100% - 290px);
}
.overview-col-1 > .row-2 > div:last-child {
  background: #ffffff;
  width: 280px;
}

/* responsive */
@media (max-width: 1199px) {
  .overview-col-1 > .row-2 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;

    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

    overflow: auto;
    padding: 1px;
    box-sizing: border-box;
  }

  .overview-col-1 > .row-2::-webkit-scrollbar {
    display: none;
  }

  .overview-col-1 > .row-2 > div:first-child {
    width: 100%;
    margin-bottom: 20px;
  }
  .overview-col-1 > .row-2 > div:last-child {
    width: 100%;
  }
}
