.inv-notes-cont {
  margin-bottom: 15px;
}

.inv-notes-cont .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 5px;
  color: #130f40;
}

.inv-notes-cont ul {
  padding-left: 18px;
  margin: 0;
}

.inv-notes-cont li {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5a577a;
  margin-bottom: 5px;
  list-style-type: disc;
}

/*  modal */
.invoice-add-notes .modal-box-content {
  width: 800px;
}

.invoice-add-notes-popup {
  padding: 8px 25px 25px 25px;
}

.invoice-add-notes-popup .ck.ck-editor__editable {
  min-height: 300px;
}

.invoice-add-notes-popup textarea {
  min-height: 300px;
  max-height: 400px;
  resize: vertical;
}

.invoice-add-notes-popup > div:first-child {
  margin-bottom: 20px;
}

.invoice-add-notes-popup > div:last-child button {
  margin-left: 10px;
}
