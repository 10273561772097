.p-relative {
    position: relative;
}
.notification-box {
    box-sizing: border-box;
}
.notification-box img {
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}
.notification-bell {
    position: relative;
}
.notification-bell img {
    display: block;
    width: 100%;
}
.notification-bell.active::after {
    content: "";
    position: absolute;
    top: 1px;
    right: 2px;
    width: 6px;
    height: 6px;
    background-color: #f50303;
    border: 1.5px solid #fff;
    border-radius: 50%;
}

.notification-cont {
    width: 350px;
    height: 660px;
    background: #fff;
    position: fixed;
    top: 55px;
    right: 20px;
    padding: 12px 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
}
.notification-cont::after {
    content: url("./icon-corner.svg");
    position: absolute;
    width: 40px;
    height: 35px;
    top: -16px;
    right: 75px;
}
.notification-cont .inst-option-cont{
    top:50px;
    right: -21px;
}
.notification-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #130f40;
}
.notification-option {
    width: 20px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    padding: 3px;
    border-radius: 5px;
    position: relative;
}

.notification-option:hover,
.notification-option.active {
    background: rgb(221, 221, 221, 0.5);
}
/* Notification Type */
.notification-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.notification-type button {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5a577a;
    background: #e8e7ec;
    border-radius: 20px;
    padding: 3px 20px;
    transition: all 0.1s ease-in-out;
}
.notification-type button.active {
    background: #ae00b9;
    color: #fff;
}

/* Notification Items */
.notification-list {
    width: 100%;
    padding-right: 5px;
    flex-grow: 1;
}

.notification-list::-webkit-scrollbar-track {
    background: transparent;
}
.notification-list:hover::-webkit-scrollbar-track {
    /* background: rgba(237, 237, 237, 0.5); */
    background: transparent;
}
.notification-list::-webkit-scrollbar {
    width: 5px;
    background: transparent;
}
.notification-list::-webkit-scrollbar-thumb {
    background: transparent;
}
.notification-list:hover::-webkit-scrollbar-thumb {
    /* background: rgba(255, 0, 0, 0.688); */
    background: transparent;
}
.notification-item {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e8e7ec;
}
.notification-item:last-child {
    border-bottom: none;
}
.notification-push {
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fbf2fb;
    border-radius: 50%;
}
.notification-push > .push-icon {
    width: 30px;
    overflow: hidden;
}
.notification-push::after {
    display: none;
    content: "";
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 7px;
    height: 7px;
    background-color: #f50303;
    border: 2px solid #fff;
    border-radius: 50%;
}
.notification-push.unread::after {
    display: block;
}
.notification-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex: 1 1 auto;
    width: 230px;
}
.notification-data h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #130f40;
    margin: 0;
    /* white-space: nowrap; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.notification-data p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgb(19, 15, 64, 0.5);
    margin: 0;
}
.notification-item-option {
    width: 17px;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}
.notification-item-option:hover,
.notification-item-option.active {
    background: #ddd;
}

.each-notification > .inst-option-cont {
    width: 180px;
}
/*See All Notification */

.see-all-notification {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #e8e7ec;
    padding: 10px 0;
}
.see-all-notification > button {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #5a577a;
    border-radius: 20px;
    padding: 4px 20px;
    transition: all 0.1s ease-in-out;
}
.see-all-notification > button:hover {
    background: #e8e7ec;
}

.inst-option-cont.each-notification {
    right: -20px;
}
