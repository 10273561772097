.user-statement-page {
  width: 1170px;
  margin: 0 auto;
}
.user-statement-page * {
  box-sizing: border-box;
}
.fin-user-statement__header {
  height: 70px;
  margin-bottom: 20px;
}
.fin-user-statement__header .user-custom-data:first-child input {
  width: 150px;
  height: 40px;
}
.fin-user-statement__header .user-custom-data input[readonly] {
  width: 250px;
  height: 40px;
}

.primary-button > button {
  background: var(--secondary);
  color: var(--secondary-50);
  text-align: center;
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  height: 40px;
  width: 168px;
  cursor: pointer;
  gap: 8px;
}
.primary-button > button > img {
  width: 20px;
}
/* .primary-button > button > span:last-of-type {
    margin-left: 8px;
} */
/* Statement body */
.fin-user-statement-cont > div:first-child {
  width: calc(100% - 370px);
}

.fin-user-statement-cont > div:last-child {
  width: 350px;
}

/* left column */
.fin-user-statement__body {
  /* width: 800px; */
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.05);
  border-radius: 20px;
  min-height: 1100px;
  padding: 30px 30px 50px 30px;
}

.fin-user-statement__body > div:first-child {
  height: 220px;
}

/* Finance User Address Section */
.fin-statement-left-col {
  width: 50%;
  overflow: hidden;
  /* min-width: 380px; */
}
.fin-statement-right-col {
  width: 40%;
  padding-left: 15px;
  padding-right: 15px;
}
/* .fin-user-statement__data {
    padding-left: 15px;
}
.fin-user-statement__data .user-info {
    padding: 0;
} */

.fin-user-statement__data.bik .user-info_avatar {
  border-radius: 0;
  width: 45px;
}

.fin-user-statement-address > .statement-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: var(--primary);
  margin-bottom: 5px;
}
/* .fin-user-statement__address-sec {
  padding-left: 15px;
} */

.fin-user-statement__address-sec .ac-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5a577a;
  margin: 0;
  margin-bottom: 10px;
}

.fin-user-statement-address > .statement-address {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-500);
}
/* Finance User Information */

.statement-info-data > div {
  height: 30px;
}

.fin-user-statement-info .statement-info-data > div > span:first-of-type {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: var(--primary-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fin-user-statement-info .statement-info-data > div > span:last-of-type {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: var(--primary);
}
/* Table section */
/* .fin-user-statement-table {
  padding: 0 15px;
} */
.fin-user-statement-table > .title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--primary);
}

.fin-user-statement-table table {
  border-color: #999;
  border-top: 1px solid;
  border-left: 1px solid;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  z-index: 0;
}

.fin-user-statement-table th {
  background: #130f4012;
  border-color: #999;
  border-bottom: 1px solid;
  border-right: 1px solid;
  color: #130f40;
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  line-height: 21px;
  text-align: center;
  width: 100px;
  padding: 5px 0;
}

.fin-user-statement-table th:nth-child(1) {
  width: 75px;
}

.fin-user-statement-table th:nth-child(3) {
  text-align: left;
  padding-left: 5px;
  width: auto;
}

.fin-user-statement-table td {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 10px 5px;
  color: #130f40;
  border-color: #999;
  border-bottom: 1px solid;
  border-right: 1px solid;
}

.fin-user-statement-table td:nth-child(3) {
  line-height: 18px;
  /* padding: 10px 5px; */
  text-align: left;
}
.fin-user-statement-table td:nth-child(3) h4 {
  margin: 0 0 5px 0px;
  font-weight: bold;
  font-size: 115%;
}
.fin-user-statement-table td:nth-child(3) p {
  margin: 0 0 5px 10px;
  opacity: 0.7;
  font-weight: 400;
}
.fin-user-statement-table td:nth-child(3) p b {
  font-weight: 700;
}

.fin-user-statement-table td:nth-child(4),
.fin-user-statement-table td:nth-child(5),
.fin-user-statement-table td:nth-child(6),
.fin-user-statement-table th:nth-child(4),
.fin-user-statement-table th:nth-child(5),
.fin-user-statement-table th:nth-child(6) {
  text-align: right;
  padding-right: 15px;
}

/* .fin-user-statement-table td:nth-child(4),
.fin-user-statement-table td:nth-child(5),
.fin-user-statement-table td:nth-child(6) {
  text-align: left !important;
  padding-left: 10px;
} */

.fin-user-statement-table .tfoot-cont td:nth-child(n + 0) {
  text-align: right;
  padding: 10px;
  padding-right: 15px;
  font-weight: bold;
}

.fin-user-statement-table td:last-child {
  position: relative;
}

/* right column */
.fin-user-statement_info-cont {
  width: 100%;
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
  border-radius: 20px;
  /* margin-bottom: 20px; */
  border: 1px solid transparent;
}

.fin-user-statement_buttons-cont {
  margin: -5px;
}

/* modal */
.status-modal-container {
  padding: 12px 20px 20px 20px;
}

@media print {
  .fin-user-statement__body {
    box-shadow: none;
  }

  .fin-user-statement-cont > div:first-child {
    width: 100%;
  }

  .fin-user-statement-cont > div:last-child {
    width: 0;
    display: none;
  }

  .user-statement-page {
    width: unset;
  }
  .fin-user-statement__header {
    display: none;
  }
}
