.invoice-search {
  width: 70%;
}
.invoice-search_box {
  background-color: #fff;
  overflow: hidden;
  height: 41px;
  border: 1px solid #f2e5ff;
  padding-left: 16px;
  box-shadow: 0 7px 20px rgb(174 0 185/5%);
  border-radius: 12px;
}
.invoice-search_box.filter {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.invoice-search_box input,
.invoice-search_box button {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary);
  height: 100%;
  background: #fff;
}

.invoice-search_box > button {
  cursor: pointer;
  opacity: 0.6;
  margin: 3px;
  width: 36px;
  height: 35px;
  border-radius: 8px;
  background: transparent;
}
.invoice-search_box button:hover,
.invoice-search_box button.active {
  background: #d0cfd9;
}
.invoice-search_box > button > img {
  width: 20px;
  display: block;
}
/* Finance invoice filter modal */
.inv-invoice-filter * {
  box-sizing: border-box;
}
.inv-invoice-filter {
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  border: 1px solid #5a577a;
  top: 42px;
  left: 0;
  border: 1px solid #f2e5ff;
  box-shadow: 0 7px 20px rgb(174 0 185/5%);
  z-index: 22;
  padding: 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.inv-invoice-filter-box > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.inv-invoice-filter-box > div > span {
  width: 100%;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #130f40;
}
.inv-invoice-filter-box > div > input,
.inv-invoice-filter-box > div > select {
  padding-left: 15px;
  outline: none;
  width: 400px;
  min-width: 400px;
  background: #ffffff;
  border: 1px solid #e8e7ec;
  border-radius: 8px;
  height: 41px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #130f40;
}
.inv-invoice-filter-box > div > select > option {
  font-size: 14px;
  font-weight: 400;
}
.inv-invoice-filter-box .inv-filter__dueDate .date-range-cont {
  height: 41px;
  width: 400px;
  min-width: 400px;
}
/* Search Button */
.search-button > button {
  cursor: pointer;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 8px;
  outline: none;
  background: #ae00b9;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
/* Reload Button */
.content-reload * {
  box-sizing: border-box;
}
.content-reload > .content-refresh-btn {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background: rgba(20, 185, 255, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
.content-reload > .content-refresh-btn:hover {
  background: #14b9ff;
}
.content-reload > .content-refresh-btn > button {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.content-reload > .content-refresh-btn > button > img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: -0.5px;
  left: 0;
}
.content-refresh-btn > button > img:first-of-type {
  opacity: 1;
}
.content-refresh-btn:hover > button > img:first-of-type {
  opacity: 0;
}
.content-refresh-btn > button > img:last-of-type {
  opacity: 0;
}
.content-refresh-btn:hover > button > img:last-of-type {
  opacity: 1;
}
/* Create Campaign create button */
.create-campaign > button {
  border: none;
  outline: none;
  background: #ae00b9;
  border-radius: 10px;
  height: 40px;
  padding: 0px 10px;
  cursor: pointer;
}
.create-campaign > button > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: block;
}
/* inv-invoice-list-container */
.inv-invoice-list-container {
  width: 1540px;
  margin: 0 auto;
}
.inv-invoice-list-container {
  width: 1170px;
  margin: auto;
}
/* user-custom-data modal */
.user-custom-data input.custom-input,
.user-custom-data select.custom-select {
  background: #ffffff;
  border: 1px solid #e8e7ec;
  color: #2b2754;
  border-radius: 8px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 12px;
  width: 195px;
  box-sizing: border-box;
  overflow: hidden;
}
.user-custom-data input:disabled {
  background: var(--primary-50);
}
.user-custom-data .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #5a577a;
  margin: 0;
}

/* Finance Summary table */
.inv-invoice-summary {
  position: fixed;
  right: -350px;
  top: 170px;
  width: 350px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
  transition: all 0.6s ease-in-out;
}
.inv-invoice-summary.active {
  right: 10px;
}
/* Create arrow */
.inv-invoice-summary > button {
  /* Create left Arrow */
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  position: absolute;
  left: -25px;
  top: 0;
  transform: rotate(180deg);
  width: 25px;
  height: 25px;
  border: none;
  outline: none;
  background-position: center;
  background-size: 20px;
  background-image: url("../../images/icons/icon-right-arrow.svg");
  background-repeat: no-repeat;
  border-radius: 0px 6px 6px 0px;
}
.inv-invoice-summary.active > button {
  display: none;
}
.inv-invoice-summary .summary-hide {
  border: 1px solid rgba(255, 27, 40, 0.1);
  outline: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: transparent;
  color: rgba(245, 3, 3, 1);
  cursor: pointer;
}
/* Customize table */
.inv-invoice-summary .bik-table > tbody > tr:first-of-type > td {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary);
  background: var(--primary-100);
  margin: 0;
}
.inv-invoice-summary .bik-table > tbody > tr,
.inv-invoice-summary .bik-table > tbody > tr > td {
  border: 2px solid var(--primary-200);
}

.inv-invoice-summary .bik-table > tbody > tr {
  height: 40px;
}
.inv-invoice-summary .bik-table > tbody > tr > td {
  font-size: 13px;
  line-height: 21px;
  padding: 0 10px;
}
.inv-invoice-summary .bik-table > tbody > tr > td:first-of-type {
  padding-left: 10px;
}
