/* send email modal */
.sms-email-cont {
  padding: 15px 25px 25px 25px;
}

.sms-email-cont textarea {
  min-height: 120px;
  max-height: 150px;
}

.sms-email-cont .ck-editor__main {
  height: 150px;
}

.sms-email-cont > div:first-child > div {
  margin-bottom: 18px;
}

.sms-email-cont > div:first-child > div:last-child {
  margin-bottom: 0;
}

.sms-email-cont > div:first-child {
  margin-bottom: 5px;
}

.sms-email-cont .attachment-btn {
  border: unset;
  outline: unset;
  background: unset;
  cursor: pointer;
  color: var(--secondary);
  margin: 0;
}

.sms-email-cont .action > button {
  margin-left: 10px;
}
