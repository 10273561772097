/* right col */
/* common */

.invoice-info-cont {
  width: 100%;
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.invoice-info-cont:last-child,
.invoice-input-area:last-child {
  margin-bottom: 0;
}

.invoice-input-area {
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
}

.invoice-input-area input,
.invoice-input-area select {
  background: #ffffff;
  border: 1px solid #e8e7ec;
  border-radius: 8px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 12px;
  width: 195px;
  box-sizing: border-box;
  overflow: hidden;
}

.invoice-input-area select {
  padding-left: 8px;
}

.invoice-input-area input::placeholder {
  color: #8987a0;
}

/* .invoice-input-area input:read-only {
    background-color: #e2e2e5;
  } */

.info-section_header {
  margin-bottom: 15px;
}

.info-section_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #130f40;
  margin: 0;
}

.invoice-info_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5a577a;
  margin: 0;
}

.invoice-info_currency-rate {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffa113;
  text-align: right;
  margin-top: 4px;
  position: relative;
  top: -6px;
  width: 100%;
  display: block;
}

.invoice-info_action-cont button {
  width: 100px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  box-sizing: border-box;
  padding: 0;
}

.invoice-info_action-cont.show button {
  max-height: 100px;
  padding: 7px 15px;
  transition: all 0.4s;
}

.invoice-info_action-cont button:last-child {
  margin-left: 10px;
}

/* arrow */
.show-content-button {
  background-color: var(--secondary);
  background-repeat: no-repeat;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 3px 1px 20px -1px rgba(19, 15, 64, 0.4);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background-position: center;
  background-size: 25px;
  transform: rotate(90deg);
  position: fixed;
  right: 0;
  visibility: hidden;
}

.show-content-button > img {
  width: 60%;
}

@media (max-width: 991px) {
  .show-content-button {
    visibility: visible;
  }

  .invoice-info-cont,
  .invoice-right-col-cont .fund-transfer-view-cont {
    overflow: unset;
  }

  .invoice-right-col-cont {
    position: fixed;
    visibility: hidden;
    box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
    background-color: #ffffff;
    width: 38%;
    height: 650px;
    overflow: auto;
    padding-left: 15px;
    padding-top: 15px;
    border-radius: 20px;
  }

  .invoice-right-col-cont.show {
    visibility: visible;
    right: 40px;
  }

  .invoice-right-col-cont .invoice-info-cont.buttons .inst-option-cont::before {
    right: 120px;
  }

  .invoice-right-col-cont .invoice-info-cont.buttons .inst-option-cont {
    right: -65px;
  }
}
