.search-filter-container {
  width: 100%;
  position: relative;
  background: #ffff;
  border-radius: 10px;
}

.search-filter-container > div {
  width: 100%;
}

.search-filter_input {
  width: calc(100% - 45px);
  height: 40px;
  border: 1px solid #f2e5ff;
  box-shadow: 0 7px 20px rgb(174 0 185/5%);
  box-sizing: border-box;
}

.search-filter_input,
.search-filter_input > input {
  border-radius: 10px;
}

.search-filter-container.active .search-filter_input,
.search-filter-container.active .search-filter_input > input {
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.search-filter_input > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary);
  padding: 0 10px;
  background: #ffff;
}

.search-filter_input > .img-filter {
  width: 40px;
  height: 32px;
  margin: 0 3px;
  box-sizing: border-box;
  border-radius: 10px;
}

.search-filter_input > .img-filter:hover,
.search-filter-container.active .img-filter {
  background-color: #d0cfd9;
}
.search-filter-container.active .img-filter {
  border-top-right-radius: 10px;
}

.search-filter_input > .img-filter img {
  width: 47%;
}

.search-filter_popup {
  position: absolute;
  top: 40px;
  z-index: 10;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #f2e5ff;
  border-top: 0px;
  background: #ffff;
  box-shadow: 0 7px 20px rgb(174 0 185/5%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*  filter row */
.filter-row {
  margin-bottom: 15px;
}

.filter-row:last-child {
  margin-bottom: 20px;
}

.filter-row > div:nth-child(1) {
  width: calc((100% / 3) * 1);
}

.filter-row > div:nth-child(2) {
  width: calc((100% / 3) * 2 - 120px);
}

.filter-row > div {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
  width: calc(100% / 2);
}
