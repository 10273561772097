/* quick response */
.overview_q-response-list-cont {
  position: relative;
  padding: 12px 15px;
  /* padding-right: 0; */
  /* margin-bottom: 10px; */
  /* border-radius: 10px;
    box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05); */
  height: 18.5%;
  transition: all 0.5s;
  overflow: hidden;
  cursor: pointer;
}

.overview_q-response-list-cont::after {
  content: "Expand";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #292929d4;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview_q-response-list-cont.active::after {
  position: unset;
}

.overview_q-response-list-cont.active {
  height: 80%;
  cursor: default;
  /* min-height: 83%; */
}

.overview_q-response-list-cont > div:first-child {
  margin-bottom: 10px;
}

.overview_q-response-list-cont .title {
  color: var(--primary);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;
}

.overview_q-response-list-cont ul {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(145, 132, 132, 0.2),
      rgba(0, 0, 0, 0)
    );
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px;
  background-attachment: local, local, scroll;
  padding: 0px;
  padding-right: 15px;
  margin: 0;
  margin-right: -15px;
  height: calc(100% - 28px);
  overflow: auto;
}

.overview_q-response-list-cont ul::-webkit-scrollbar-thumb,
.overview_q-response-list-cont ul::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.overview_q-response-list-cont ul:hover::-webkit-scrollbar-thumb {
  background: #d780dc;
}

.overview_q-response-list-cont ul:hover::-webkit-scrollbar-track {
  background-color: rgb(237, 237, 237);
}

.overview_q-response-list-cont li {
  color: var(--primary-700);
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: justify;
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
  /* border-radius: 8px; */
  list-style-type: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
}

.overview_q-response-list-cont li::before {
  position: absolute;
  top: 50%;
  left: 0px;
  content: "";
  background-color: var(--secondary);
  width: 3px;
  height: 60%;
  transform: translateY(-50%);
}

.overview_q-response-list-cont li {
  position: relative;
  padding: 5px 8px;
  margin: 0;
}

.overview_q-response-list-cont li:hover,
.overview_q-response-list-cont li.active {
  background-color: #f3f3f5;
}

.overview_q-response-list-cont li button {
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 32px;
  width: 20px;
  height: 20px;
  display: none;
}

.overview_q-response-list-cont li button:last-child {
  right: 5px;
}

.overview_q-response-list-cont li button > img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: -0.5px;
  left: 0;
}

.overview_q-response-list-cont li button > img:first-of-type {
  opacity: 1;
}

.overview_q-response-list-cont li button:hover > img:first-of-type,
.overview_q-response-list-cont li.active button.active > img:first-of-type {
  opacity: 0;
}
.overview_q-response-list-cont li button > img:last-of-type {
  opacity: 0;
}

.overview_q-response-list-cont li button:hover > img:last-of-type,
.overview_q-response-list-cont li button.active > img:last-of-type {
  opacity: 1;
}

.overview_q-response-list-cont li button > img {
  width: 100%;
  height: 100%;
}

.overview_q-response-list-cont li:hover button,
.overview_q-response-list-cont li.active button {
  display: block;
}
