.page-cont.bm-orders-page {
  max-width: 1170px;
}

.bm-orders-page .page-header-cont .search-input {
  width: 100%;
  margin-left: 20px;
}

.bm-orders-page .page-header-cont .search-input > input {
  width: 100%;
}

.bm-orders-page .page-header-cont .reload-btn {
  width: 40px;
  height: 40px;
}
