.bm-create-order-modal .asset-type-selection-area {
  margin-bottom: 15px;
}

.bm-create-order-modal .assets-type-cont {
  width: 85%;
}

.bm-create-order-modal .assets-container {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
}

.bm-create-order-modal .assets-container li {
  list-style-type: none;
  margin-bottom: 10px;
  height: 35px;
}

.bm-create-order-modal .assets-container li input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
}

.bm-create-order-modal .assets-container li input[type="text"] {
  height: 100%;
}

.bm-create-order-modal .assets-container li .asset-item-row > div,
.bm-create-order-modal .assets-container li > div {
  height: 100%;
}

.bm-create-order-modal .assets-container .asset-item-row > div:nth-child(1) {
  width: 20px;
}

.bm-create-order-modal .assets-container .asset-item-row > div:nth-child(2) {
  width: calc(100% - 100px - 40px);
  height: 100%;
  border-radius: 8px;
  background-color: #fbeafb;
  color: var(--secondary);
  box-sizing: border-box;
  text-align: center;
}

.bm-create-order-modal .assets-container .asset-item-row > div:nth-child(3) {
  width: 100px;
}

.bm-create-order-modal .section-divider {
  margin-bottom: 10px;
}

.bm-create-order-modal .section-divider > div:first-child {
  white-space: nowrap;
}

.bm-create-order-modal .section-divider .separator {
  width: 100%;
  margin-left: 10px;
  border: transparent;
  border-bottom: 1px solid #d0cfd9;
}
