.page-cont.customer-overview {
  padding: 0;
  max-width: 100%;
  height: 100%;
  /* height: 885px; */
}
.customer-overview-section * {
  box-sizing: border-box;
}
/* common */
.customer-overview-section .editable {
  position: relative;
  z-index: 1;
}

.customer-overview-section .editable:hover {
  overflow: unset !important;
}

.customer-overview-section .editable:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5px;
  left: -5px;
  padding: 5px;
  background-color: #f3f3f5;
  border-radius: 6px;
  z-index: -1;
}

.customer-overview-section .list-search {
  width: 18px;
  height: 18px;
}

.customer-overview-section .list-search > img {
  width: 100%;
  height: 100%;
}

.customer-overview-section .list-add-button {
  color: var(--secondary);
  background: #fbeafb;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

.customer-overview-section .list-add-button:hover,
.customer-overview-section .list-add-button.active {
  background-color: var(--secondary);
  color: #ffffff;
}

/* section */
.customer-overview-section {
  /* border-radius: 10px; */
  /* border: 1px solid #36519d; */
  border-top: transparent;
  background: #ffffff;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

/* Disable hover effect and points event for all elements when saving something */
.customer-overview-section.disabled-mode *:hover,
.customer-overview-section.disabled-mode [role="button"] {
  pointer-events: none;
}

.customer-overview-section .section-body {
  padding: 20px 15px 20px 20px;
  height: calc(100% - 40px);
  overflow: hidden;
}

/* body */
.customer-overview-section .section-body > .overview-col-1 {
  width: calc(100% - 320px);
}

.customer-overview-section .section-body > .overview-col-2 {
  width: 310px;
  padding: 1px;
  overflow: auto;

  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(145, 132, 132, 0.2),
      rgba(0, 0, 0, 0)
    );
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px;
  background-attachment: local, local, scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-width: none;
  -webkit-scrollbar {
    display: none;
  }
}

.customer-overview-section .section-body > .overview-col-2::-webkit-scrollbar {
  display: none;
}

/* common */
.overview_section-item {
  box-shadow: 0px 0px 0px 0.9px rgb(19 15 64 / 14%);
  border-radius: 10px;
}
/* 
.customer-overview-section .overview-col-2 .section,
.customer-overview_customerinfo,
.customer-overview-section .overview-col-1 > .row-1,
.customer-overview-section .overview-col-1 > .row-2 > .col-1,
.customer-overview-section .overview-col-1 > .row-2 > .col-2 > div {
  box-shadow: 0px 0px 0px 0.9px rgb(19 15 64 / 14%);
  border-radius: 10px;
} */

@media (max-width: 1199px) {
  .customer-overview-section .section-body > .overview-col-1 {
    width: calc(100% - 260px);
  }

  .customer-overview-section .section-body > .overview-col-2 {
    width: 250px;
  }
}
