.feature-asset-selection-cont {
  margin-bottom: 10px;
  width: 100%;
}

.feature-asset-selection-row {
  margin-bottom: 15px;
}

/* .feature-asset-selection-row:last-child {
    padding-bottom: 15px;
  } */

.feature-asset-selection-row.pvc > div:nth-child(1) {
  width: 220px;
}

.feature-asset-selection-row.pvc > div:nth-child(2) {
  width: calc(100% - 220px - 40px);
}

.feature-asset-selection-row.pvc > div:nth-child(3) {
  width: 20px;
}

.feature-asset-selection-row.concrete > div:nth-child(1) {
  width: 80px;
}

.feature-asset-selection-row.concrete > div:nth-child(2) {
  width: 80px;
}

.feature-asset-selection-row.concrete > div:nth-child(3) {
  width: 80px;
}

.feature-asset-selection-row.concrete > div:nth-child(4) {
  width: calc(100% - 240px - 60px);
}

.feature-asset-selection-row.concrete > div:nth-child(5) {
  width: 20px;
}

.feature-asset-selection-row > div img {
  width: 100%;
}

.feature-asset-price-field {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #fbeafb;
  color: var(--secondary);
  box-sizing: border-box;
  padding: 0 10px;
  text-align: center;
}

.feature-asset-add-more-btn {
  height: 30px;
}
