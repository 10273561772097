/* activity */
.invoice-activity_row {
  margin-bottom: 12px;
}

.invoice-activity_row:last-child {
  margin-bottom: 0;
}

.invoice-activity_row > div:first-child {
  width: 40px;
}

.invoice-activity_row > div:last-child {
  width: calc(100% - 50px);
}

.invoice-activity_displayName {
  color: #130f40;
}

.invoice-activity_date {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(19, 15, 64, 0.4);
  margin: 0;
}

.invoice-activity_avatar {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.invoice-activity_avatar img {
  height: 100%;
}
