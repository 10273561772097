/* invoice reopen */
.invoice-reopen-popup {
  padding: 8px 25px 25px 25px;
}

.invoice-reopen-popup textarea {
  min-height: 150px;
  max-height: 350px;
  resize: vertical;
}

.invoice-reopen-popup textarea::placeholder {
  opacity: 0.6;
}

.invoice-reopen-popup textarea:focus::placeholder {
  opacity: 0;
}

.invoice-reopen-popup > div:first-child {
  margin-bottom: 20px;
}

.invoice-reopen-popup > div:last-child button {
  margin-left: 10px;
}
