.ck.ck-balloon-panel.ck-powered-by-balloon[class*="position_border"] {
  display: none !important;
}

.ck-content {
  height: 100% !important;
}

.ck.ck-editor__editable {
  border: 1px solid #d0cfd9 !important;
  border-radius: 8px !important;
  box-shadow: unset !important;
  outline: none !important;
}

.ck.ck-editor__editable * {
  margin: 8px 0;
}

.ck.ck-balloon-panel {
  z-index: 9999999 !important;
}
