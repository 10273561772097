/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
  transition: all 0.5s ease;
  cursor: all-scroll;
}
::-webkit-scrollbar-thumb {
  background: #d780dc;
  border-radius: 10px;
  transition: all 0.5s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: #c74dce;
}
::-webkit-scrollbar-track {
  background-color: rgb(237, 237, 237);
}
:root {
  --primary: rgb(19, 15, 64);
  --primary-700: #5a577a;
  --primary-500: #8987a0;
  --primary-300: #b9b7c6;
  --primary-200: #d0cfd9;
  --primary-100: #e8e7ec;
  --primary-50: #f3f3f5;
  --primary-rgb: 19, 15, 64;

  /* Secondary */
  --secondary: #ae00b9;
  --secondary-700: #c74dce;
  --secondary-500: #d780dc;
  --secondary-300: #e7b3ea;
  --secondary-200: #efccf1;
  --secondary-100: #f7e6f8;
  --secondary-50: #fbf2fb;

  /* Success */
  --success: #00b15b;
  --success-700: #4dc98d;
  --success-300: #b3e8ce;
  --success-200: #ccefde;
  --success-100: #e6f8ef;

  /* Waring */
  --warning: #ffa113;
  --warning-700: #ffbd5a;
  --warning-300: #ffe3b9;
  --warning-200: #ffecd0;
  --warning-100: #fff6e8;

  /* Error Code */
  --error: #f50303;
  --error-700: #f84f4f;
  --error-300: #fcb4b4;
  --error-200: #fdcdcd;
  --error-100: #fee6e6;

  /*  */
}
/* Image */
img {
  display: block;
}
.object-cover {
  object-fit: cover;
}
.object-contain {
  object-fit: contain;
}
.object-center {
  object-position: center;
}
/* Initial Text Color */
.text-primary {
  color: var(--primary);
}
.text-secondary {
  color: var(--secondary);
}
/* Text Color */
.text-primary-700 {
  color: var(--primary-700);
}
.text-primary-500 {
  color: var(--primary-500);
}
.text-primary-300 {
  color: var(--primary-300);
}
.text-primary-200 {
  color: var(--primary-200);
}
.text-primary-100 {
  color: var(--primary-100);
}
.text-primary-50 {
  color: var(--primary-50);
}
.text-secondary-700 {
  color: var(--secondary-700);
}
.text-secondary-500 {
  color: var(--secondary-500);
}
.text-secondary-300 {
  color: var(--secondary-300);
}
.text-secondary-200 {
  color: var(--secondary-200);
}
.text-secondary-100 {
  color: var(--secondary-100);
}
.text-secondary-50 {
  color: var(--secondary-50);
}

/* Align */
.text-center {
  text-align: center;
}

.text-unlerline {
  text-decoration: underline;
}

/* Width & Height */
.w-200 {
  width: 200px;
}
.w-100p {
  width: 100%;
}
.h-100p {
  height: 100%;
}
.h-15 {
  height: 15px;
}
.h-20 {
  height: 15px;
}
/* Margin */
.m-0 {
  margin: 0;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
/* Margin top */
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 45px;
}
/* Margin Bottom */
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}

/* Padding */
.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}

/* Flex Spacing */
.gap-0 {
  gap: 0;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-20 {
  gap: 20px;
}
.gap-25 {
  gap: 25px;
}
.gap-30 {
  gap: 30px;
}
.gap-35 {
  gap: 35px;
}
.gap-40 {
  gap: 40px;
}
.gap-45 {
  gap: 45px;
}
.gap-50 {
  gap: 50px;
}
/* Display */
.d-block {
  display: block;
}
.p-absolute {
  position: absolute;
}

/* Fonts Weight*/
.font-400 {
  font-weight: normal;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}

/* Font Sizing */

.amount-font {
  font-family: Lato, sans-serif;
  font-weight: 700 !important;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;
}
.text-14 {
  font-size: 16px;
  line-height: 21px;
}
.text-16 {
  font-size: 16px;
  line-height: 25px;
}
.text-18 {
  font-size: 18px;
  line-height: 28px;
}
.text-20 {
  font-size: 20px;
  line-height: 31px;
}
.text-22 {
  font-size: 22px;
  line-height: 34px;
}
.text-24 {
  font-size: 24px;
  line-height: 37px;
}
.text-26 {
  font-size: 26px;
  line-height: 40px;
}
.text-28 {
  font-size: 28px;
  line-height: 43px;
}
.text-30 {
  font-size: 30px;
  line-height: 46px;
}
/* Text indent */
.text-indent-25 {
  text-indent: 25px;
}
.text-indent-50 {
  text-indent: 50px;
}
.text-indent-75 {
  text-indent: 75px;
}
.text-indent-100 {
  text-indent: 100px;
}
/* Text alignment */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
/* Text Transform */
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-lowercase {
  text-transform: lowercase;
}
/* Text Decoration */

.text-dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
/* Animate */
.animate {
  transition: all 0.25s ease-in-out;
}

/*  Root */
html,
body,
#root {
  height: 100%;
}

/* React ToolTip Design */
#myTooltip {
  border-radius: 8px;
  font-weight: 400;
  background: #130f40;
  z-index: 99999;
}
/* .stop-scroll {
  position: fixed;
} */

.stop-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.flatpickr-input[readonly] {
  background-image: url("../../images/icons/icon-calender-fill.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 17px;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.selected.endRange {
  background: #ae00b9 !important;
  border-color: #ae00b9 !important;
}

/* disabled view */
.disabled-view {
  filter: grayscale(100%);
  cursor: default;
  pointer-events: none;
}

/* user-custom-btn */
.user-custom-btn button {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  border: 1px solid rgb(232, 231, 236, 0.5);
  border-radius: 8px;
  padding: 7px;
  transition: all 0.25s ease-in-out;
}
.user-custom-btn.hover button:hover {
  background: rgba(232, 231, 236, 0.5);
}

/* flie drag and drop area */
.file-selection-area {
  border-radius: 10px;
  border: 1.5px dashed rgba(174, 0, 185, 0.3);
  background: rgba(174, 0, 185, 0.05);
  text-align: center;
  vertical-align: center;
  height: 150px;
  position: relative;
}

.file-selection-area > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: fill;
  display: block;
}

.file-selection-area_title {
  color: var(--secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.file-selection-area_title span {
  color: var(--primary-700);
}

.file-selection-area .remove-attachment {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.file-selection-area .remove-attachment > img {
  width: 100%;
  height: 100%;
}
