.info-sidebar-cont {
  background: #fafbfb;
  width: 800px;
  height: calc(100% - 48px);
  position: fixed;
  right: -739px;
  top: 48px;
  z-index: 99;
  border-right: 1px solid #e8e7ec;
  transition: all 0.4s ease-in-out;
}

.info-sidebar-cont .edit-float-btn {
  position: fixed;
  bottom: 122px;
  right: 60px;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
.info-sidebar-cont .edit-float-btn > img {
  width: 100%;
}

.info-sidebar-cont .infomenu-list-1 > li > button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.info-sidebar-cont .layer-1 {
  background-color: #f3f3f5;
  width: 66px;
}
.info-sidebar-cont .layer-1 .btn-menu {
  height: 68px;
}
.info-sidebar-cont .infomenu-list-1 {
  padding: 0;
  margin: 0;
  width: 100%;
}
.info-sidebar-cont .infomenu-list-1 > li {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.info-sidebar-cont .infomenu-list-1 > li > button {
  text-decoration: none;
  display: block;
  text-align: center;
  width: 56px;
  height: 70px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.info-sidebar-cont .infomenu-list-1 > li > button > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #5a577a;
}
.info-sidebar-cont .infomenu-list-1 > li > button > div {
  display: block;
  margin: 0px auto;
  width: 50px;
  height: 38px;
  margin-bottom: 5px;
  position: relative;
  padding: 6px 15px;
  border-radius: 15%;
  transition: all 0.2s ease-in-out;
  background: whitesmoke;
}
.info-sidebar-cont .infomenu-list-1 > li > button > div > img {
  width: 24px;
  height: 24px;
  position: absolute;
  transition: all 0.2s ease-in-out;
}
.info-sidebar-cont .infomenu-list-1 > li > button > div > img:nth-child(1) {
  opacity: 0;
}
.info-sidebar-cont .infomenu-list-1 > li > button > div > img:nth-child(2) {
  opacity: 1;
}

.info-sidebar-cont .infomenu-list-1 > li.active > button > div,
.info-sidebar-cont .infomenu-list-1 > li:hover > button > div {
  background-color: #f7e6f8;
  font-weight: bold;
}
.info-sidebar-cont .infomenu-list-1 > li.active > button > span,
.info-sidebar-cont .infomenu-list-1 > li:hover > button > span {
  color: #130f40;
}
.info-sidebar-cont
  .infomenu-list-1
  > li.active
  > button
  > div
  > img:nth-child(1),
.info-sidebar-cont
  .infomenu-list-1
  > li:hover
  > button
  > div
  > img:nth-child(1) {
  opacity: 1;
}
.info-sidebar-cont
  .infomenu-list-1
  > li.active
  > button
  > div
  > img:nth-child(2),
.info-sidebar-cont
  .infomenu-list-1
  > li:hover
  > button
  > div
  > img:nth-child(2) {
  opacity: 0;
}

/* Drawer Open/Close */
.info-sidebar-cont.drawer-opened {
  right: 0;
}

/* .info-sidebar-cont.drawer-opened .sidebar-content-area {
  visibility: visible;
} */

.info-sidebar-cont.drawer-opened .edit-float-btn {
  visibility: visible;
}

/* Cont Size */
.info-sidebar-cont .menu-cont {
  /* height: 100%; */
  min-height: 100vh;
  transition: all 0.25s ease-in-out;
  padding-top: 48px;
  box-sizing: border-box;
  background-color: #fafbfb;
}
.info-sidebar-cont .menu-cont.d-opened {
  margin-left: 300px;
}
.info-sidebar-cont .menu-cont.d-closed {
  margin-left: 66px;
}

/* content area */
.sidebar-content-area {
  width: 100%;
  /* visibility: hidden; */
  overflow: auto;
  transition: all 0.4s ease-in-out;
}
.sidebar-content-area::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sidebar-content-area > div {
  padding: 20px;
}

.sidebar-content-area .not-found {
  border-radius: 0;
}
