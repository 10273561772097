.role-manage-section .section-header {
  margin-bottom: 10px;
}

.role-manage-section .section-header .reload-button {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

.role-manage-section .section-header .section-title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: var(--primary);
}

.role-manage-section .role-avatar {
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-right: 15px;
}

.role-manage-section .role-avatar img,
.role-manage-section .role-avatar > span {
  height: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
}

.role-manage-section .role-status {
  padding: 1px 7px;
  font-size: 13px;
  border-radius: 5px;
  display: inline-block;
}

.role-manage-section .role-status.default {
  background: rgba(174, 0, 185, 0.22);
  color: rgb(174, 0, 185);
}

.role-manage-section .role-status.pending {
  background: rgba(246, 146, 32, 0.1);
  color: #f69220;
}

/* make owner warning */
.role-owner-warning > div:nth-child(1) {
  height: 100px;
}

.role-owner-warning > div:nth-child(2) {
  padding: 0 25px;
}

.role-owner-warning > div:nth-child(2) > p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--error);
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

.role-owner-warning > div:nth-child(2) > div > p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: var(--error);
  margin: 0;
  text-align: left;
}

.role-owner-warning > div:nth-child(2) > div {
  margin-bottom: 5px;
}

.role-owner-warning > div:nth-child(2) > div:last-child {
  margin-bottom: 0;
}

.role-owner-warning > div:nth-child(2) > div > input {
  margin-top: 5px;
  margin-right: 8px;
}

.role-owner-warning > div:nth-child(3) {
  padding-top: 20px;
  padding-bottom: 35px;
}

.role-owner-warning > div:nth-child(3) > button {
  margin-left: 10px;
}
