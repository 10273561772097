@media print {
  .template-message,
  .adv-web-menu,
  .adv-menu,
  .page-header-cont,
  .invoice-page-container > div:last-child {
    display: none;
  }
  .invoice-page-cont,
  .invoice-page-container > div:first-child {
    width: 100% !important;
    max-width: 100% !important;
  }

  .invoice-page-container {
    height: 100%;
    min-height: 0 !important;
  }

  /* .invoice .row-1 {
    height: unset !important;
  } */

  .menu-cont.d-opened,
  .menu-cont.d-closed,
  .invoice-page-cont {
    margin: 0 !important;
    padding: 0 !important;
    background-color: #ffffff !important;
    /* background-color: #f10909 !important; */
  }

  .invoice {
    border-radius: 0 !important;
    box-shadow: unset !important;
    padding: 1px;
  }

  .invoice thead {
    display: table-header-group;
  }

  .invoice tbody {
    display: table-row-group;
  }

  .non-printable {
    display: none !important;
    margin: 0;
    padding: 0;
  }
  .printable {
    display: block !important;
  }

  /* font color */
  .invoice .address-view > p,
  .invoice .invoice-info > div span:first-child,
  .invoice .inv-table td:nth-child(2) p {
    color: #5a577a !important;
    opacity: 1 !important;
  }

  .inv-notes-cont li,
  .invoice .address-view .name {
    color: #130f40 !important;
  }
}

/* on print */
.printable {
  display: none;
}

/* invoice page */
.invoice-page-cont {
  width: 1170px;
  padding: 0;
  margin: auto;
}

/* invoice search */
.invoice-search-box {
  width: 200px;
  border: 1px solid rgb(28, 38, 73, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0 12px;
}
.invoice-search-box > input {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  height: 40px;
  color: rgb(28, 38, 73);
  background: transparent;
}
.invoice-search-icon {
  width: 15px;
  display: block;
}
.invoice-search-icon > img {
  width: 100%;
  display: block;
  object-position: center;
  object-fit: cover;
}

.invoice-page-container {
  position: relative;
  min-height: 1100px;
  /* height: 1100px; */
}

.invoice-page-container > div {
  height: 100%;
}

.invoice-page-container > div:first-child {
  width: calc(100% - 370px);
}

.invoice-page-container > div:last-child {
  width: 350px;
}

/* hover view */
.invoice .clickable {
  position: relative;
  cursor: pointer;
  z-index: 0;
}
.invoice .clickable:hover::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #130f401a;
  z-index: -1;
  position: absolute;
  top: -15px;
  left: -15px;
  padding: 15px;
  border-radius: 8px;
}

.invoice .clickable:hover .del-button,
.invoice .clickable:hover .edit-button {
  visibility: visible;
}
.non-clickable .clickable,
.non-clickable .hover {
  cursor: default !important;
}

.non-clickable .clickable:hover {
  overflow: hidden !important;
}

.non-clickable .clickable:hover::after,
.non-clickable .hover:hover::after {
  cursor: default !important;
  display: none !important;
}

/* invoice view */
.invoice {
  width: 100%;
  height: 100%;
  padding: 30px 30px 50px 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
  border-radius: 20px;
  position: relative;
}

.invoice-logo {
  display: none;
  width: 155px;
}
.invoice-logo > img {
  width: 100%;
}

.invoice.no-data::after {
  position: absolute;
  content: " ";
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
}

.invoice .row-1 {
  /* height: 210px; */
  margin-bottom: 30px;
}
.invoice .row-1 > div:nth-child(1) {
  width: 50%;
}

.invoice .row-1 .user-info {
  margin-bottom: 13px;
}

.invoice .address-view .co-info {
  margin-bottom: 10px;
}

/* .invoice .row-1 > div:nth-child(1) > div > div {
  margin-bottom: px;
} */

.invoice .row-1 > div:nth-child(2) {
  width: 40%;
}
.invoice .row-2 {
  margin-bottom: 30px;
}

.invoice .issue-date,
.invoice .due-date {
  white-space: nowrap;
}

/* User View */

.invoice .user-view .name {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #130f40;
}
.invoice .user-view .contact {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8987a0;
}
.invoice .user-view .invoice-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

/* Address View */
.invoice .address-view .header {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #130f40;
  margin: 0;
  margin-bottom: 7px;
}
.invoice .address-view .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5a577a;
  margin-bottom: 6px;
}

.invoice .address-view .name:last-child {
  margin-bottom: 0;
}

.invoice .address-view .address {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8987a0;
  margin: 0;
  margin-bottom: 1px;
}
.invoice .address-view .contact {
  color: #130f40;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin: 0;
}

/* invoice-info */

.invoice .invoice-info > div {
  height: 27px;
}

.invoice .invoice-info h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #130f40;
  margin: 0;
}

.invoice .invoice-info > div span {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #130f40;
}
.invoice .invoice-info > div span:first-child {
  color: #8987a0;
}

/* Order Header */
.invoice .order-header {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #130f40;
  margin: 0;
  margin-bottom: 14px;
}

/* Order Table */

.invoice .inv-table {
  border-color: #999;
  border-top: 1px solid;
  border-left: 1px solid;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  z-index: 0;
}

.invoice .price-amount {
  font-family: Lato, sans-serif;
  font-weight: 700 !important;
}

.invoice .inv-table th {
  background: #130f4012;
  border-color: #999;
  border-bottom: 1px solid;
  border-right: 1px solid;
  color: #130f40;
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  line-height: 21px;
  text-align: center;
  padding: 5px 0;
}

.invoice .inv-table td {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 10px 0;
  color: #130f40;
  border-color: #999;
  border-bottom: 1px solid;
  border-right: 1px solid;
}

.invoice .inv-table .tfoot-cont td:nth-child(n + 0) {
  text-align: right;
  padding: 10px;
  padding-right: 15px;
  font-weight: bold;
}
.invoice .inv-table .tfoot-cont td:last-child {
  text-align: center;
}

.invoice .info-action-button {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  z-index: 20;
}

.invoice .inv-table .edit-button {
  margin-bottom: 12px;
}

.invoice .del-button,
.invoice .edit-button {
  width: 20px;
  visibility: hidden;
}

.invoice .del-button > img,
.invoice .edit-button > img {
  width: 100%;
}

/* Summery Table */
.summery-table {
  width: 300px;
  border-color: #999;
  border-top: 1px solid;
  border-left: 1px solid;
  margin-bottom: 25px;
}
.invoice .summery-table th {
  background: #130f4012;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  border-color: #999;
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 0px 10px;
  color: #130f40;
}
.invoice .summery-table td {
  font-size: 13px;
  line-height: 30px;
  text-align: left;
  border-color: #999;
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 0px 10px;
  color: #130f40;
}
.invoice .summery-table td:nth-child(2) {
  text-align: right;
}

/* inst popup */
.inst-invoice .inst-option-cont {
  width: 200px;
  top: 56px;
}

.inst-invoice .inst-option-cont .menu-logo {
  width: 20px;
}

.inst-invoice .inst-option-cont button {
  padding: 5px 10px;
}

.inst-invoice .inst-option-cont button span {
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 991px) {
  .invoice-page-container > div:first-child {
    width: 100%;
  }

  .invoice-page-container > div:last-child {
    width: 0;
    position: absolute;
    top: 0;
  }
}
