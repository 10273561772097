.fac-preview {
  font-size: 12px;
  width: 100px;
}

.fac-preview b {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  position: relative;
  font-size: 12px;
  color: rgba(90, 87, 122, 1);
  font-weight: 400;
  text-decoration: underline;
  text-decoration-color: rgba(185, 183, 198, 1);
}
.fac-preview b:hover {
  overflow: visible;
  display: unset;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
  text-decoration: none;
}

.fac-preview b:hover::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -3px;
  display: block;
  width: 100%;
  height: 100%;
  padding: 3px 5px;
  box-shadow: 0 0 7px 2px #c8c8c8c4;
  border-radius: 3px;
  background-color: white;
  z-index: -1;
}
.fac-preview div {
  font-size: 12px !important;
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  color: rgba(90, 87, 122, 1);
}

.fac-preview div > a {
  font-size: 12px !important;
  color: var(--secondary) !important;
}
