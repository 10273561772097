.inv-account-page * {
  box-sizing: border-box;
}
.inv-account__body {
  min-height: 750px;
}
.inv-account-page {
  width: 1170px;
  margin: 0 auto;
}
.inv-account__header {
  height: 80px;
}
.inv-account-page
  .inv-account__header
  .inv-account-header__btn
  > .button.button-solid_purple {
  padding: 9px 15px;
}
.inv-account-page .bik-table-parent {
  min-height: auto;
}

.inv-account-page .bik-table-parent .fac-text {
  text-decoration: underline;
  color: var(--secondary);
}

/* Account Section */
