.adv-menu {
  background: #fafbfb;
  width: 66px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  border-right: 1px solid #e8e7ec;
  transition: all 0.25s ease-in-out;
}

/* .adv-menu .app-inactive {
  background-image: url("../../images/icons/icon-exclamation.svg");
  background-repeat: no-repeat;
  background-position: top 10px right 10px;
  background-size: 20px;
} */

.adv-menu .layer-1 {
  background-color: #f3f3f5;
  width: 66px;
}
.adv-menu .layer-1 .btn-menu {
  height: 68px;
}
.adv-menu .adv-list-1 {
  padding: 0;
  margin: 0;
  width: 100%;
  height: calc(100% - 68px);
  overflow: auto;
}

.adv-menu .adv-list-1::-webkit-scrollbar {
  width: 0;
}

.adv-menu .adv-list-1 > li {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.adv-menu .adv-list-1 > li.project-manage {
  height: calc(100% - 350px);
  overflow: hidden;
}

.adv-menu .adv-list-1 > li.project-manage:hover::before {
  display: none !important;
}

.adv-menu .adv-list-1 > li > a {
  text-decoration: none;
  display: block;
  text-align: center;
  width: 56px;
  height: 70px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.adv-menu .adv-list-1 > li > a > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #5a577a;
}

.adv-menu .adv-list-1 > li > a > span.inventory {
  font-size: 10px;
}

.adv-menu .adv-list-1 > li > a > div {
  display: block;
  margin: 0px auto;
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
  position: relative;
  padding: 6px 15px;
  border-radius: 15%;
  transition: all 0.2s ease-in-out;
}
.adv-menu .adv-list-1 > li > a > div > img {
  width: 24px;
  height: 24px;
  position: absolute;
  transition: all 0.2s ease-in-out;
}
.adv-menu .adv-list-1 > li > a > div > img:nth-child(1) {
  opacity: 0;
}
.adv-menu .adv-list-1 > li > a > div > img:nth-child(2) {
  opacity: 1;
}

.adv-menu .adv-list-1 > li.active > a > div,
.adv-menu .adv-list-1 > li:hover > a > div {
  background-color: #f7e6f8;
  font-weight: bold;
}
.adv-menu .adv-list-1 > li.active > a > span,
.adv-menu .adv-list-1 > li:hover > a > span {
  color: #130f40;
}
.adv-menu .adv-list-1 > li.active > a > div > img:nth-child(1),
.adv-menu .adv-list-1 > li:hover > a > div > img:nth-child(1) {
  opacity: 1;
}
.adv-menu .adv-list-1 > li.active > a > div > img:nth-child(2),
.adv-menu .adv-list-1 > li:hover > a > div > img:nth-child(2) {
  opacity: 0;
}

/* Layer 2 */
.adv-menu .layer-2 {
  position: relative;
  background-color: #ffffff;
}

.adv-menu .layer-2.app-inactive {
  display: none;
}

.adv-menu .adv-l2-cont {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 66px;
  top: 55px;
  width: 234px;
  height: calc(100% - 70px);
  border-radius: 15px;
  display: none;
}
.adv-menu .adv-list-2 {
  list-style: none;
  margin: 0;
  padding: 0;
  height: calc(100% - 50px - 60px);
  overflow: auto;
}
.adv-menu .adv-list-2 > li > a {
  text-decoration: none;
  color: #130f40;
  margin: 4px 15px;
  border-radius: 8px;
  position: relative;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.adv-menu .adv-list-2 > li > a > img {
  width: 20px;
  height: 20px;
  margin: 7px;
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.adv-menu .adv-list-2 > li > a > img:first-of-type {
  opacity: 1;
}
.adv-menu .adv-list-2 > li.active > a > img:first-of-type,
.adv-menu .adv-list-2 > li:hover > a > img:first-of-type {
  opacity: 0;
}
.adv-menu .adv-list-2 > li.active > a > img:last-of-type,
.adv-menu .adv-list-2 > li > a > img:last-of-type {
  opacity: 0;
}
.adv-menu .adv-list-2 > li.active > a > img:last-of-type,
.adv-menu .adv-list-2 > li:hover > a > img:last-of-type {
  opacity: 1;
}

.adv-menu .adv-list-2 > li > a > span {
  line-height: 38px;
  margin-left: 42px;
  color: #5a577a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.adv-menu .adv-list-2 > li.active > a,
.adv-list-2 > li:hover > a {
  background: #fbf2fb;
}

/* .adv-menu .adv-list-2 > li.active > a > img,
.adv-menu .adv-list-2 > li:hover > a > img {
    filter: grayscale(0);
} */

.adv-menu .adv-list-2 > li.active > a > span,
.adv-menu .adv-list-2 > li:hover > a > span {
  color: #130f40;
}

/* L2 Head */
.adv-menu .adv-l2-head {
  margin: 5px 5px 10px 5px;
  background: #fbf2fb;
  padding: 5px 15px;
  border-radius: 12px;
}
.adv-menu .adv-l2-head > img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.adv-menu .adv-l2-head > span {
  font-size: 20px;
  font-weight: bold;
}

/* Show L2 */
.adv-menu.drawer-opened .adv-list-1 > li.active .adv-l2-cont,
.adv-menu .adv-list-1 > li.app-inactive .adv-l2-cont {
  display: none !important;
}
.adv-menu .adv-list-1 > li:hover .adv-l2-cont {
  background: #fff;
  box-shadow: 0 0 10px 0px #9999999c;
  display: block;
}

.adv-menu .adv-list-1 > li:hover::before {
  content: "";
  display: block;
  position: absolute;
  left: 53px;
  top: 13px;
  background: transparent;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #ffffff transparent transparent;
  z-index: 101;
}

/* .adv-menu .adv-list-1.project-manage-menu > li {
  position: relative;
}

.adv-menu .adv-list-1.project-manage-menu > li:hover::before {
  display: none !important;
}

.adv-menu .adv-list-1.project-manage-menu .inst-option-cont,
.adv-menu .adv-list-1.project-manage-menu .inst-option-cont::before {
  top: unset;
  right: unset;
}

.adv-menu .adv-list-1.project-manage-menu .inst-option-cont {
  bottom: 100%;
  left: 0;
}

.adv-menu .adv-list-1.project-manage-menu .inst-option-cont::before {
  bottom: -15px;
  left: 8px;
  transform: rotate(180deg);
} */

.adv-menu.drawer-opened .adv-list-1 > li.active::before,
.adv-menu .adv-list-1 > li.app-inactive::before {
  display: none;
}

/* Drawer Open/Close */
.adv-menu.drawer-opened {
  width: 300px;
}
.adv-menu .active .adv-l2-cont {
  width: 0px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.adv-menu.drawer-opened .active .adv-l2-cont {
  width: 234px;
}

.adv-menu.drawer-closed .adv-list-1 > li.active .adv-l2-cont,
.adv-menu.drawer-closed .adv-list-1 > li.active:hover .adv-l2-cont {
  width: 234px;
}

/* Layer 2 */
.adv-menu .layer-2 .adv-l2-cont {
  display: block;
  width: 234px;
  height: 100%;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  z-index: 1;
}
.adv-menu.drawer-closed .layer-2 .adv-l2-cont {
  width: 0;
}

/* Cont Size */
.menu-cont {
  height: 100%;
  /* min-height: 100vh; */
  transition: all 0.25s ease-in-out;
  padding-top: 48px;
  box-sizing: border-box;
  background-color: #fafbfb;
  overflow: auto;
}

/* .menu-cont::-webkit-scrollbar {
  width: 0;
}

.menu-cont::-webkit-scrollbar-thumb {
  background: rgb(237, 237, 237);
} */

.menu-cont.d-opened {
  margin-left: 300px;
}
.menu-cont.d-closed {
  margin-left: 66px;
}

@media (max-width: 1199px) {
  .menu-cont.d-opened {
    margin-left: 66px;
  }
}
