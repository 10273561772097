.api-page * {
    box-sizing: border-box;
    margin: 0;
}

.api-page .api-page__header {
    height: 80px;
    width: 1170px;
    margin: 0 auto;
}

.api-page .api-page__body {
    width: 1170px;
    margin: 0 auto;
}
.api-page__body--menu {
    width: 350px;
    height: 454px;
    overflow: hidden;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05);
}
.api-page__body--content {
    width: 790px;
    height: 454px;
    padding: 25px 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05);
    background: #fff;
    background-image: url("./../../images/bg-illustration/web-api-interface-flat.png");
    background-repeat: no-repeat;
    background-position: top right;
}
.api-menu-item {
    border-bottom: 1px solid var(--primary-100);
    height: 91px;
    cursor: pointer;
    width: 100%;
}

.api-menu-item.active {
    background-color: rgb(243, 243, 245, 0.5);
}
.api-menu-item > .icon {
    width: 50px;
}
.api-menu-item > .icon > img {
    width: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}

.api-menu-item > .content > h3 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}
.api-menu-item > .content > p {
    color: var(--primary-700);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}
