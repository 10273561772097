/* notes view */
.overview_notes-list-cont {
  background-color: #ffffff;
  /* box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05);
    border-radius: 10px; */
  overflow: hidden;
  padding: 12px 15px;
  /* padding-right: 0; */
  height: 18.5%;
  transition: all 0.5s;
  cursor: pointer;
}

.overview_notes-list-cont.active {
  height: 80%;
  cursor: default;
  /* min-height: 83%; */
}

.overview_notes-list-cont.active .btn-edit,
.overview_notes-list-cont.active .btn-delete {
  visibility: visible;
}

.overview_notes-list-cont .section-header {
  margin-bottom: 10px;
  /* padding-right: 15px; */
  position: relative;
}

.overview_notes-list-cont .section-title {
  color: var(--primary);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;
}

.overview_notes-list-cont ul {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(145, 132, 132, 0.2),
      rgba(0, 0, 0, 0)
    );
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px;
  background-attachment: local, local, scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  padding: 0px;
  padding-right: 15px;
  margin: 0;
  margin-right: -15px;
  overflow: auto;
  height: calc(100% - 30px);
}

.overview_notes-list-cont ul::-webkit-scrollbar-thumb,
.overview_notes-list-cont ul::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.overview_notes-list-cont ul:hover::-webkit-scrollbar-thumb {
  background: #d780dc;
}

.overview_notes-list-cont ul:hover::-webkit-scrollbar-track {
  background-color: rgb(237, 237, 237);
}

.overview_notes-list-cont li {
  position: relative;
  color: var(--primary-700);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  /* margin-bottom: 8px; */
  list-style-type: none;
  padding: 8px 0px;
  border-bottom: 1px solid var(--primary-200);
  /* overflow: hidden; */
}

.overview_notes-list-cont li > div:first-child {
  margin-bottom: 5px;
}

.overview_notes-list-cont li:last-child {
  border-bottom: none;
}

.overview_notes-list-cont .note-author {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.overview_notes-list-cont .note-author > img {
  width: 100%;
  height: 100%;
}

.overview_notes-list-cont .btn-edit,
.overview_notes-list-cont .btn-delete {
  font-size: 12px;
  line-height: 18px;
  margin-right: 7px;
  text-decoration: underline;
  visibility: hidden;
}

.overview_notes-list-cont .btn-edit {
  color: var(--primary-700);
}

.overview_notes-list-cont .btn-delete {
  color: #f50303bf;
}

.overview_notes-list-cont .note-date {
  color: var(--primary-500);
  text-align: right;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  width: 100%;
  margin: 0;
  margin-bottom: 3px;
}

.overview_notes-list-cont .note-text.minimized {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overview_notes-list-cont .note-text.expand {
  display: unset;
}

.overview_notes-list-cont .note-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, transparent, white 70%);
  padding-right: 15px;
}
