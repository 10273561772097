/* .page-cont.expense-page-cont {
  max-width: 1170px;
} */
.expense-page-cont table th {
  font-size: 14px !important;
  line-height: 21px !important;
}

.expense-page-cont table td span {
  font-size: 12px !important;
}

/* modal */
.expense-action-modal .user-search-field-cont {
  margin-bottom: 20px;
}
