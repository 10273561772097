/* con-2 > row-2 > conversation */
.customer-conversation-container {
  height: 100%;
  border-radius: 10px;
  /* border-radius: 10px;
  box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05); */
  background: var(--primary-50);
}

/* header */
.customer-conversation-container .con-header {
  border-bottom: 1px solid var(--primary-200);
  min-height: 40px;
  padding-left: 20px;
  padding-right: 5px;
  overflow: hidden;
}

.customer-conversation-container .con-header .reload-button {
  width: 30px;
  height: 30px;
}

.customer-conversation-container .con-header .reload-button.mr-2 {
  margin-right: 10px;
}

.customer-conversation-container .con-header .reload-button > button {
  border: none;
  outline: none;
  background: transparent;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.customer-conversation-container .con-header .reload-button > button > img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: -0.3px;
  left: 0;
}
.customer-conversation-container
  .con-header
  .reload-button
  > button
  > img:first-of-type {
  opacity: 1;
}
.customer-conversation-container
  .con-header
  .reload-button:hover
  > button
  > img:first-of-type {
  opacity: 0;
}
.customer-conversation-container
  .con-header
  .reload-button
  > button
  > img:last-of-type {
  opacity: 0;
}
.customer-conversation-container
  .con-header
  .reload-button:hover
  > button
  > img:last-of-type {
  opacity: 1;
}

.customer-overview-section .con-header .title {
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
}

/* body */
.customer-overview-section .con-body {
  flex-grow: 1;
  height: auto;
  padding: 15px 20px;
  overflow: auto;
  box-sizing: border-box;
}

.customer-overview-section .con-thread-container > div:first-child {
  margin-bottom: 10px;
}

.customer-overview-section .con-thread {
  width: 100%;
}

.customer-overview-section .con-thread > div {
  position: relative;
  border-radius: 8px;
  margin-bottom: 2px;
  padding: 5px 10px 5px 15px;
  box-sizing: border-box;
  min-width: 45%;
  max-width: 80%;
  width: fit-content;
}

/* thread menu */
.con-thread-container .thread-menu-cont {
  display: none;
  position: absolute;
}
.con-thread-container .thread-menu-cont > div {
  position: relative;
}

.customer-overview-section .con-thread:hover .thread-menu-cont,
.customer-overview-section .con-thread.active .thread-menu-cont {
  display: block;
}

.con-thread-container .sender-cont .thread-menu-cont {
  top: 10px;
  left: -25px;
}

.con-thread-container .receiver-cont .thread-menu-cont {
  top: 10px;
  right: -25px;
}

.con-thread-container .thread-menu-cont .thread-menu {
  width: 15px;
  position: relative;
  z-index: 2;
}
.con-thread-container .thread-menu-cont .thread-menu > img {
  width: 90%;
}

.con-thread-container .thread-menu-cont .thread-menu::after {
  content: " ";
  position: absolute;
  top: -4px;
  left: -4px;
  width: 90%;
  height: 100%;
  background-color: var(--primary-200);
  border-radius: 5px;
  padding: 4px;
  z-index: -1;
}

.customer-overview-section .con-thread-container .con-text {
  color: var(--primary-700);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin: 0;
  word-break: break-word;
  white-space: pre-line;
}

.customer-overview-section .con-thread-container .con-time {
  color: var(--primary-500);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-align: right;
  margin: 0;
}

/* receiver */
.customer-overview-section .receiver-cont .con-thread > div {
  background: #ffffff;
}

.customer-overview-section .receiver-cont .con-thread:first-child > div {
  border-radius: 8px 8px 8px 0;
}

.customer-overview-section .receiver-cont .con-thread:last-child > div {
  border-radius: 0 8px 8px 8px;
}

.customer-overview-section .con-thread-container .receiver-info {
  margin-bottom: 5px;
}
.customer-overview-section .con-thread-container .receiver-img {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

.customer-overview-section .con-thread-container .receiver-img > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}

.customer-overview-section .con-thread-container .receiver-name {
  color: #ffa113;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* sender */
.customer-overview-section .sender-cont .con-thread > div {
  background: #ffecd0;
}

.customer-overview-section .sender-cont .con-thread:first-child > div {
  border-radius: 8px 8px 0px 8px;
}

.customer-overview-section .sender-cont .con-thread:last-child > div {
  border-radius: 8px 0px 8px 8px;
}

/* footer */
.customer-conversation-container .con-footer {
  margin: 0 20px;
  padding: 20px 0;
  /* height: 70px; */
}

.file-attachment-row {
  padding: 12px 5px;
  box-sizing: border-box;
  width: 100%;
}

.file-attachment-row > div {
  width: 55px;
  height: 50px;
  background: #ffffff;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
}

.file-attachment-row > div:hover::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-color: #ffffff70;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.file-attachment-row > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.file-attachment-row .remove-attachment-btn {
  display: none;
}

.file-attachment-row .remove-attachment-btn > img {
  width: 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
}
.file-attachment-row > div:hover .remove-attachment-btn {
  display: block;
}

.customer-conversation-container .chat-input-container {
  padding: 5px 5px 5px 15px;
  border-radius: 25px;
  background: #ffffff;
  box-sizing: border-box;
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
}

.customer-conversation-container .chat-input-container .col-1,
.customer-conversation-container .chat-input-container .col-2 {
  width: 40px;
  padding-bottom: 10px;
}

.customer-conversation-container .chat-input-container .col-2 {
  position: relative;
}

.customer-conversation-container .chat-input-container .col-2 aside {
  position: absolute;
}

.customer-conversation-container .chat-input-container .chat-attach,
.customer-conversation-container .chat-input-container .chat-emoji {
  width: 22px;
  height: 22px;
}

.customer-conversation-container .chat-input-container .chat-attach > img,
.customer-conversation-container .chat-input-container .chat-emoji > img {
  width: 100%;
  height: 100%;
}

.customer-conversation-container .chat-input-container .col-3 {
  width: calc(100% - 40px - 40px - 50px);
}

.customer-conversation-container .chat-input-container .col-3 > div {
  width: 100%;
}

.customer-conversation-container .chat-input-container .col-3 textarea {
  border: none;
  outline: none;
  background: #ffffff;
  width: 100%;
  resize: none;
}

.customer-conversation-container .chat-input-container .col-4 {
  width: 50px;
}

.customer-conversation-container .chat-input-container .chat-send-button {
  width: 40px;
  height: 40px;
}

.customer-conversation-container .chat-input-container .chat-send-button > img {
  width: 100%;
  height: 100%;
}
