.inv-add-refund {
  padding: 20px 25px 25px 25px;
}

.inv-add-refund > div:nth-child(1) {
  margin-bottom: 10px;
}

.inv-add-refund > div:nth-child(2) {
  margin-bottom: 15px;
}

.inv-add-refund .info > div {
  margin-bottom: 8px;
}
.inv-add-refund .info > div:last-child {
  margin-bottom: 0;
  margin-top: 15px;
}

.inv-add-refund .info input {
  height: 35px;
}

.inv-add-refund .info span {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
}

.inv-add-refund .info span:first-child {
  color: var(--primary-700);
}

.inv-add-refund .info span:last-child {
  text-align: right;
}

.inv-add-refund .refund-inp-cont > div {
  width: calc((100% - 5px) / 2);
}

.inv-add-refund .refund-inp-cont input.total {
  background: #fbf2fb !important;
  color: var(--secondary) !important;
  border-radius: 8px;
  border: transparent;
}

.inv-add-refund .pay-option {
  padding: 5px 10px;
  margin-left: -10px;
  margin-right: -10px;
}

.inv-add-refund .pay-option.active {
  background: rgba(245, 0, 87, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.inv-add-refund .pay-option.active span {
  color: var(--primary) !important;
}

.inv-add-refund .pay-option span {
  margin-left: 10px;
}

.inv-add-refund .action button {
  margin-left: 10px;
}

.inv-add-refund .action button {
  margin-left: 10px;
}
