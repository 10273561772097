.page-cont.customer-list-page {
  max-width: 1170px;
}

.customer-list-page .page-header-cont > div:nth-child(2) {
  margin: 0 20px;
}

.customer-list-page .page-header-cont .date-range-cont {
  width: 100%;
}

/* table */
.customer-list-page p.data-length-text {
  font-size: 14px;
  color: var(--primary-500);
  margin: 0;
  white-space: nowrap;
  padding-left: 20px;
}

.customer-list-page p.data-length-text span {
  color: var(--secondary);
}

.customer-list-page .bik-paginate {
  padding-right: 20px;
}

.customer-list-page .bik-paginate__item input {
  height: 100%;
  padding: 5px;
}

/* modal */
.customer-add-modal {
  padding: 20px;
}

.customer-add-modal > div:first-child {
  margin-bottom: 20px;
}

.customer-add-modal > div:last-child button {
  width: 110px;
}

.customer-add-modal .input-row:first-child {
  margin-bottom: 20px;
}
