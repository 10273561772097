/* overview-col-2 */
.overview-col-2 .section {
  /* border-radius: 20px;
  box-shadow: 0px 0px 50px 0px rgba(19, 15, 64, 0.05); */
  background: #ffffff;
  padding: 12px 20px;
  margin-bottom: 10px;
}

.overview-col-2 .section:last-child {
  margin-bottom: 0;
}

/* .overview-col-2 .section > div:first-child {
  margin-bottom: 10px;
} */

.overview-col-2 .section-title {
  color: var(--primary);
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  margin: 0;
}

.overview-col-2 .section .add-btn {
  color: var(--secondary);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

/* order */
.overview-col-2 .section.order-section .order-title,
.overview-col-2 .section.order-section .order-amount,
.overview-col-2 .section.order-section .total-amount {
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
  margin: 0;
}

.overview-col-2 .section.order-section .order-title {
  margin-bottom: 7px;
}

.overview-col-2 .section.order-section .total-amount {
  color: var(--secondary);
}

.overview-col-2 .section.order-section .lo-date,
.overview-col-2 .section.order-section .view-all-text,
.overview-col-2 .section.order-section .total-text {
  color: var(--primary-700);
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px; /* 100% */
  margin: 0;
}

.overview-col-2 .section.order-section .view-all-text {
  display: inline-block;
  text-decoration: none;
}

.overview-col-2 .section.order-section .order-date,
.overview-col-2 .section.order-section .order-quantity {
  color: var(--primary-700);
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 100% */
  margin: 0;
}

.overview-col-2 .section.order-section > div {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--primary-200);
}

.overview-col-2 .section.order-section > div:first-child,
.overview-col-2 .section.order-section > div:last-child {
  padding-bottom: 10px;
  margin-bottom: 0px;
  border: unset;
}

/* graph */
.overview-col-2 .section.graph-section > div {
  width: 100%;
}

.overview-col-2 .section.graph-section > div > img {
  width: 100%;
}

/* media */

.overview-col-2 .section.media-section > div:first-child {
  margin-bottom: 10px;
  padding: 10px 0;
  min-height: 36px;
}

.overview-col-2 .section.media-section > div:first-child {
  margin-bottom: 7px;
}

.overview-col-2 .section.media-section .edit-text {
  color: var(--primary-500);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.overview-col-2 .section.media-section .file-info-row-cont {
  margin-bottom: 10px;
}

.overview-col-2 .section.media-section .file-info-row-cont > div:first-child {
  width: calc(100% - 20px);
}

.overview-col-2 .section.media-section .file-info-row-cont > div:last-child {
  width: 15px;
}

.overview-col-2 .section.media-section .file-view-cont {
  height: auto;
  padding: 7px 5px;
  border-radius: 8px;
  background-color: var(--primary-100);
  overflow: hidden;
}

.overview-col-2 .section.media-section .file-view-cont:last-child {
  margin-bottom: 0;
}

.overview-col-2 .section.media-section .file-view-cont > div {
  overflow: hidden;
}

.overview-col-2 .section.media-section .file-view-cont > div:first-child {
  width: 20px;
}

.overview-col-2 .section.media-section .file-view-cont > div:first-child > img {
  width: 100%;
}

.overview-col-2 .section.media-section .file-view-cont > div:last-child {
  width: calc(100% - 20px - 7px);
}

.overview-col-2 .section.media-section .file-info-row-cont .file-delete > img {
  width: 100%;
}

.overview-col-2 .section.media-section .file-avatar {
  display: block;
  width: 100%;
  height: 100%;
  margin-right: 10px;
}

.overview-col-2 .section.media-section .file-title {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-500);
}
