.inv-config-page-cont .config-invoice-section {
  margin-bottom: 30px;
}

.inv-config-page-cont .config-invoice-item {
  width: 100%;
  height: 110px;
  padding: 0 10px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid var(--primary-200);
  position: relative;
  margin-bottom: 15px;
}

.inv-config-page-cont .config-invoice-item:last-child {
  margin-bottom: 0;
}

.inv-config-page-cont .config-invoice-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.inv-config-page-cont .config-invoice-item .change-invoice-btn {
  width: 65px;
  height: 31px;
  font-size: 12px;
  background-color: var(--secondary);
  color: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 7px;
  right: 7px;
}

.inv-config-page-cont .config-invoice-item.default {
  /* background-color: #29292985; */
  color: var(--primary);
  border-radius: 8px;
}

/* .inv-config-page-cont .config-invoice-item.default::after {
  content: "Set Header";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background-color: #29292985;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
