.inv-add-payment {
  padding: 20px 25px 25px 25px;
}

.inv-add-payment > div:nth-child(1) {
  margin-bottom: 10px;
}

.inv-add-payment > div:nth-child(2) {
  margin-bottom: 15px;
}

.inv-add-payment .info > div {
  margin-bottom: 8px;
}
.inv-add-payment .info > div:last-child {
  margin-bottom: 0;
}

.inv-add-payment .info input {
  height: 35px;
}

.inv-add-payment .info textarea {
  resize: vertical;
  min-height: 60px;
  max-height: 100px;
  overflow: auto;
}

.inv-add-payment .info-text {
  font-size: 12px;
  color: #c74dce;
  white-space: nowrap;
  padding-top: 5px;
  text-align: left;
  margin: 0;
  /* max-height: 0;
  overflow: hidden;
  transition: all 0.2s; */
}

.inv-add-payment .info span {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
}

.inv-add-payment .info span:first-child {
  color: var(--primary-700);
}

.inv-add-payment .info span:last-child {
  /* width: 130px; */
  text-align: right;
}

.inv-add-payment .pay-option {
  padding: 5px 10px;
  margin-left: -10px;
  margin-right: -10px;
}

.inv-add-payment .pay-option.active {
  background: rgba(245, 0, 87, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.inv-add-payment .pay-option.active span {
  color: var(--primary) !important;
}

.inv-add-payment .pay-option span {
  margin-left: 10px;
}

.inv-add-payment .action button {
  margin-left: 10px;
}

.inv-add-payment .vat-note-text {
  color: #f50303;
}
