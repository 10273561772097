.bik-paginate * {
    box-sizing: border-box !important;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 25px;
}
.bik-paginate {
    width: 100%;
    min-width: 220px;
    padding: 14px 10px;
    overflow: hidden;
}
.bik-paginate.width {
    width: 300px;
}
.bik-paginate__item {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.bik-paginate.left .bik-paginate__item {
    justify-content: flex-start;
}
.bik-paginate.center .bik-paginate__item {
    justify-content: center;
}
.bik-paginate.right .bik-paginate__item {
    justify-content: flex-end;
}
.bik-paginate__item > li {
    width: 33px;
    min-width: 33px;
    height: 33px;
    display: inline-block;
    text-align: center;
    font-family: "Poppins", serif;
    font-size: 14px;
    line-height: 33px;
    font-weight: 500;
    color: rgba(19, 15, 64, 0.7);
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}
.bik-paginate__item > li.active,
.bik-paginate__item > li:hover {
    background-color: rgba(174, 0, 185, 0.05);
    color: rgba(174, 0, 185, 1);
}
