.user-info {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  padding: 9px 0;
  text-align: left;
  width: 100%;
}

.user-info > div:last-child {
  overflow: hidden;
}

.user-info.hover:hover::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: -10px;
  left: -10px;
  background: #e8e7ec;
  border-radius: 10px;
  padding: 10px;
}

.user-info_avatar {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-right: 15px;
}

.user-info_avatar img,
.user-info_avatar > span {
  height: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
}

.user-info_name {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #130f40;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-info_email {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8987a0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-info_phone {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8987a0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-info_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8987a0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
/* v2 */
.user-info.v2 .user-info_avatar {
  width: 40px;
  height: 40px;
}

.user-info.v2 .user-info_name {
  font-size: 20px;
  line-height: 24px;
}

.user-info.v2 .user-info_email {
  font-weight: 400;
}

/* v3 */
.user-info.v3 {
  padding: 0;
}
.user-info.v3 .user-info_avatar {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.user-info.v3 .user-info_name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.user-info.v3 .user-info_email {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  max-width: 180px;
}

/* V4 */
.user-info.v4 {
  padding: 0;
  position: relative;
}
.user-info.v4 .user-info_avatar {
  width: 35px;
  height: 35px;
  min-width: max-content;
  margin-right: 10px;
}
.user-info.v4 .user-floating-menu {
  position: absolute;
  right: 25%;
  bottom: 50px;
  z-index: 10;
  width: 300px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0px 30px 50px 0px rgba(19, 15, 64, 0.1);
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #e8e7ec;
}

.user-info.v4 .user-floating-menu .user-info_avatar {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}
.user-info.v4 .user-floating-menu .user-info_name {
  white-space: normal;
}

/*arrow icon using after content */

.user-info.v4 .user-floating-menu::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 50%;
  margin-right: -5px;
  width: 20px;
  height: 20px;
  background: #ffffff;
  transform: rotate(45deg);
  z-index: 1;
}

/* User info v5 */
.user-info.v5 .user-floating-menu .user-info_avatar {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}
