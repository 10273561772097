/* assets */
.overview-col-2 .section.assets-section .add-btn {
  color: var(--secondary);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.overview-col-2 .section.assets-section > div {
  /* padding-bottom: 10px; */
  /* margin-bottom: 10px; */
  padding: 10px 0;
  border-bottom: 1px solid var(--primary-200);
  min-height: 36px;
}

.overview-col-2 .section.assets-section > div span {
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.overview-col-2 .section.assets-section > div p.asset-quantity {
  color: var(--primary-700);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  margin: 0;
  margin-top: 5px;
}

.overview-col-2 .section.assets-section > div span:first-child {
  color: var(--primary-700);
}

.overview-col-2 .section.assets-section > div:first-child,
.overview-col-2 .section.assets-section > div:last-child {
  border: unset;
}

.overview-col-2 .section.assets-section > div:last-child {
  padding-bottom: 0;
  margin-bottom: 0px;
}

.customer-overview-section .section.assets-section .info-edit-area input {
  max-height: 30px !important;
}

/* modals */
.assets-tab-cont {
  margin-bottom: 20px;
}

.asset-type-title {
  color: var(--primary-500);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
