.customer-overview_customerinfo {
  height: 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.customer-overview_customerinfo > div {
  padding: 0 10px;
}

.customer-overview_customerinfo > div:nth-child(1) {
  /* flex: 0 1 auto; */
  flex: 0 0 25%;
  width: 25%;
  /* width: 300px; */
}
.customer-overview_customerinfo > div:nth-child(2) {
  /* width: calc(100% - 300px - 250px - 50px - 40px); */
  flex: 1 1 auto;
}
.customer-overview_customerinfo > div:nth-child(3) {
  flex: 0 1 auto;
  /* width: 250px; */
}

.customer-overview_customerinfo > div:nth-child(4) {
  width: 50px;
}

.customer-overview_customerinfo > div:nth-child(4) img {
  height: 50px;
}

.customer-overview_customerinfo_address > div:nth-child(1) {
  width: 40px;
}
.customer-overview_customerinfo_address > div:nth-child(1) > img {
  width: 100%;
}

.customer-overview_customerinfo_address > div:nth-child(2) {
  width: calc(100% - 40px - 10px);
}

.customer-overview_customerinfo_address > div:nth-child(2) span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--primary);
  margin: 0;
  display: block;
}

.customer-overview_customerinfo_joined-date > span,
.customer-overview_customerinfo_source > span {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
  margin: 0;
  display: block;
  white-space: nowrap;
}

.customer-overview_customerinfo_joined-date > span:first-child {
  color: var(--primary-500);
}

.customer-overview_customerinfo_source > span {
  color: var(--primary-500);
  margin-right: 10px;
}

.customer-overview_customerinfo_source .source-logo {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

/* visit modal */

.hour-selector-label {
  margin-bottom: 10px;
}

.hour-selector-label > span:first-child {
  font-size: 16px;
  color: var(--primary);
  margin: 0;
  display: block;
}

.hour-selector-label > span:nth-child(2) {
  font-size: 14px;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  padding: 0 3px;
  border-radius: 5px;
  margin: 0;
  margin-left: 10px;
  display: block;
}

.hour-selector-label > span:nth-child(2):hover {
  background-color: var(--secondary);
  border: 1px solid transparent;
  color: #ffffff;
}

.hour-selector-label > span:nth-child(3) {
  font-size: 13px;
  border: 1px solid transparent;
  background-color: #fbf2fb;
  color: var(--secondary);
  border-radius: 5px;
  margin: 0;
  padding: 0px 5px;
  box-sizing: border-box;
  margin-left: 10px;
  display: block;
}

.hour-selector {
  border: 1px solid var(--secondary);
  border-radius: 5px;
  font-size: 14px;
  color: var(--primary);
  white-space: nowrap;
  padding: 5px;
  box-sizing: border-box;
  width: calc((100% - 40px) / 4);
  margin-bottom: 10px;
}

.hour-selector.picked {
  background-color: var(--success) !important;
  border: 1px solid var(--success) !important;
  color: #ffffff !important;
}

.hour-selector.disabled,
.hour-selector.disabled:hover {
  /* filter: grayscale(100%) !important; */
  cursor: default;
  pointer-events: none;
  /* background-color: var(--success); */
  color: gray !important;
  border: 1px solid gray !important;
}

.hour-selector.selected {
  position: relative;
}

.hour-selector.selected,
.hour-selector.selected:hover {
  color: #ffffff !important;
  background-color: var(--secondary) !important;
}

.hour-selector:hover {
  cursor: pointer;
  background-color: var(--secondary-700);
  color: #ffffff;
}

.approx-time {
  width: 100%;
  height: 40px;
  background-color: #fbf2fb;
  border: 1px solid transparent;
  border-radius: 8px;
  padding-right: 10px;
  font-size: 14px;
  color: var(--secondary);
}

@media (max-width: 1399px) {
  .customer-overview_customerinfo .user-info_avatar {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px !important;
  }

  .customer-overview_customerinfo .user-info_name {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .customer-overview_customerinfo_address > div:nth-child(1) {
    width: 30px;
  }
  .customer-overview_customerinfo_address > div:nth-child(2) {
    width: calc(100% - 30px - 10px);
  }
  .customer-overview_customerinfo_address > div:nth-child(2) span {
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .customer-overview_customerinfo_joined-date > span {
    font-size: 14px;
    line-height: 21px;
  }
}
