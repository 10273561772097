/* calender */
/* .date-picker .form-control.input,
.date-picker .flatpickr-calendar::after,
.date-picker .flatpickr-calendar:before {
  display: none;
} */
.date-picker .flatpickr-calendar,
.date-picker .flatpickr-rContainer,
.date-picker .dayContainer,
.date-picker .flatpickr-days {
  width: 100%;
  max-width: 100%;
}

.date-picker .flatpickr-innerContainer {
  /* max-height: 245px; */
  max-height: 235px;
  /* border: unset; */
}

.date-picker .flatpickr-calendar {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/* background transparent */
.date-picker .flatpickr-months .flatpickr-month,
.date-picker span.flatpickr-weekday,
.date-picker .flatpickr-weekdays {
  background: transparent;
}
.date-picker .flatpickr-months .flatpickr-month {
  margin-bottom: 20px;
}
.date-picker .flatpickr-prev-month {
  left: 50px !important;
}
.date-picker .flatpickr-next-month {
  right: 50px !important;
}

.date-picker .flatpickr-prev-month,
.date-picker .flatpickr-next-month {
  fill: #000 !important;
  background: #f1f1f4;
  border-radius: 50%;
  width: 30px;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.date-picker .flatpickr-current-month,
.date-picker .cur-month {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding-top: 2px;
}

.date-picker .flatpickr-days {
  border: unset;
}

.date-picker .flatpickr-weekdays {
  margin-bottom: 20px;
  height: unset;
}
.date-picker span.flatpickr-weekday {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8987a0 !important;
}

/* selected date bg */

/* .date-picker .flatpickr-day.selected.startRange,
.date-picker .flatpickr-day.inRange {
  box-shadow: 5px 0px #f7e6f8 !important;
} */

.date-picker .flatpickr-day.selected,
.date-picker .flatpickr-day.endRange,
.date-picker .flatpickr-day.startRange,
.date-picker
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.date-picker
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.date-picker
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  background-color: #ae00b9 !important;
  border-color: #ae00b9 !important;
  box-shadow: 1px 0px #f7e6f8 !important;
}

.date-picker .flatpickr-day.inRange {
  background-color: #f7e6f8 !important;
  border-color: #f7e6f8 !important;
  box-shadow: 5px 0px #f7e6f8 !important;
}
