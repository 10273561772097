.inv-trans-page * {
  box-sizing: border-box;
}

.inv-trans-page__header,
.inv-trans-page__body {
  width: 1170px;
  margin: 0 auto;
}

.inv-trans-page__header {
  height: 80px;
}

/* .inv-trans_date-range {
  width: 300px;
  height: 40px;
} */

.inv-trans-page__header .inst-option-cont {
  right: -17px;
}

.inv-trans-page__header .dot-menu {
  height: 35px;
}
.inv-trans-page__header .dot-menu > img {
  height: 100%;
}

/* inv-trans-page */
.inv-trans-page .feature-trans-summary {
  right: -350px;
}

.inv-trans-page .feature-trans-summary.active {
  right: -10px;
}
