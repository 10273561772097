.pdf-layout {
  width: 100%;
  table-layout: fixed;
}

.pdf-layout > thead,
.pdf-layout > tfoot {
  display: none;
}

.pdf-layout > thead.pdf-header > img {
  width: "800px";
  height: "100px";
}

.pdf-layout-foot {
  display: none;
}

.pdf-layout-foot > img {
  width: 100%;
}

@media print {
  .pdf-layout {
    background-color: #ffffff;
    position: unset;
  }

  .pdf-layout > thead,
  .pdf-layout > tfoot {
    display: unset;
  }
  .pdf-layout .pdf-cont {
    padding-left: 45px;
    padding-right: 20px;
  }

  .pdf-layout > thead {
    display: table-header-group;
    page-break-inside: avoid;
  }
  .pdf-layout > tfoot {
    display: table-footer-group;
    page-break-inside: avoid;
  }

  .pdf-layout-foot {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: 100px; */
  }

  .pdf-no-break {
    page-break-inside: avoid;
  }

  /* version-2 */
  .pdf-layout.v2 .pdf-layout-foot,
  .pdf-layout.v2 > thead,
  .pdf-layout.v2 > tfoot,
  .layout-hidden {
    visibility: hidden;
  }

  .pdf-layout.v2 .pdf-cont {
    padding-left: 50px;
  }

  @page {
    @bottom-center {
      content: "Continue to the next page...";
      margin: 10mm;
      font-size: 12px;
    }
  }
}
