/* not found */

.not-found {
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    box-sizing: border-box;
    overflow: hidden;
}

.not-found img {
    max-width: 415px;
    margin-top: 100px;
    margin-bottom: 20px;
}

.not-found h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #130f40;
    margin: 0;
    margin-bottom: 18px;
}

.not-found button {
    width: 150px;
    height: 45px;
}

.placeholder-mode {
    filter: grayscale(1);
}

.placeholder-mode:hover {
    background-color: transparent !important;
}

.placeholder-mode * {
    cursor: default;
    pointer-events: none;
    user-select: none;
}

.placeholder-mode span,
.placeholder-mode a {
    color: transparent !important;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: #999;
    background: linear-gradient(
        to right,
        #eeeeee 10%,
        #dddddd 18%,
        #eeeeee 33%
    );
    background-size: 800px 104px;
    border-radius: 10px;
    opacity: 1 !important;
    margin-bottom: 2px;
    display: inline-block;
}

.placeholder-mode button,
.placeholder-mode input[type="checkbox"] {
    visibility: hidden;
}

.placeholder-mode span {
    line-height: 18px !important;
}
.placeholder-mode span * {
    color: transparent !important;
}
.placeholder-mode span img {
    visibility: hidden;
}

.placeholder-mode .gap {
    margin-bottom: 5px;
}

@keyframes loading {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}