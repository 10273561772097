.overview-section-header {
  height: 40px;
  /* border-radius: 10px 10px 0px 0px; */
  background: #36519d;
}

.overview-section-header ul {
  margin: 0;
  padding: 0;
  width: 98%;
  height: 100%;
}

.overview-section-header li {
  position: relative;
  list-style-type: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0px 8px 0px 15px;
  width: 15%;
  max-width: 200px;
  min-width: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.overview-section-header li:hover,
.overview-section-header li.active {
  background: #302989;
}

.overview-section-header li:first-child {
  width: 40px;
  min-width: 40px;
  border-radius: 20px 0px 0px 0px;
  padding: 0px;
}

.overview-section-header li:first-child > img {
  width: 20px;
  height: 20px;
}

.overview-section-header li:last-child {
  width: 40px;
  min-width: 40px;
  padding: 0;
  font-size: 17px;
}

.overview-section-header li .tab-title {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overview-section-header li .tab-close {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 100;
  background: #36519d;
}

.overview-section-header li > div {
  width: 100%;
}
