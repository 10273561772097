/* delete invoice */
.inv-delete-body > p {
  margin: 0;
  margin-bottom: 10px;
  color: #130f40;
}

.inv-delete-body .input-area {
  margin-bottom: 15px;
}

.inv-delete-body textarea {
  min-height: 50px;
  max-height: 110px;
  resize: vertical;
  overflow: auto;
  display: block;
}

.inv-delete-body textarea.required {
  border-color: #f50303;
}

.inv-delete-body .action button {
  margin-right: 10px;
}
