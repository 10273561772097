/* search */
.address-updater-container .search-icon img {
  width: 20px;
  margin-left: 10px;
}

.address-updater-container .modal-box-body {
  overflow: unset;
}

.address-updater-body {
  padding: 8px 25px 25px 25px;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.address-updater-body .ad-button-area {
  margin-top: 18px;
}

.button-tel,
.button-fax {
  width: 35px;
  height: 20px;
  margin-left: 5px;
}

.button-minus {
  width: 20px;
  height: 20px;
}

.button-minus {
  background-image: url("../../images/icons/icon-minus.svg");
  background-repeat: no-repeat;
}

.button-tel {
  background-image: url("../../images/icons/icon-add-telephone.svg");
  background-repeat: no-repeat;
}

.button-fax {
  background-image: url("../../images/icons/icon-add-fax.svg");
  background-repeat: no-repeat;
}

.button-minus.active,
.button-minus:hover {
  background-image: url("../../images/icons/icon-minus-fill.svg");
  background-repeat: no-repeat;
}

.button-tel.active,
.button-tel:hover {
  background-image: url("../../images/icons/icon-add-telephone-fill.svg");
  background-repeat: no-repeat;
}

.button-fax.active,
.button-fax:hover {
  background-image: url("../../images/icons/icon-add-fax-fill.svg");
  background-repeat: no-repeat;
}

.hidden-input-btn {
  height: 35px;
}

.ad-field-inactive,
.ad-user-search-area > div {
  margin: 0 !important;
  max-height: 0;
  transition: 0s ease-out;
  overflow: hidden;
}

.ad-field-active,
.ad-user-search-area.active > div {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
}

/* button */

button.ad-button {
  border: unset;
  outline: unset;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  box-sizing: border-box;
  width: 120px;
}

.button-save {
  background: #17dc69;
  color: #fff;
  padding: 9px 0px;
  border-radius: 8px;
  margin-left: 10px;
}

.button-cancel {
  background: rgba(245, 0, 87, 0.1);
  color: #f50057;
  padding: 9px 0px;
  border-radius: 8px;
}

/* input area */
/* .ad-user-search-area {
  padding: 5px;
} */
.ad-user-search-area > div {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.1);
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  width: calc(100% - 50px);
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px;
  transition: all 0.2s;
  visibility: hidden;
}

.ad-user-search-area.active > div {
  visibility: visible;
}

.ad-input-area {
  width: calc((100% - 20px) / 2);
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 6px;
}

.ad-input-area.fill {
  width: 100%;
}

.ad-input-area > label {
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  margin-left: 2px;
  color: #130f40;
}

.ad-input-area > textarea {
  min-height: 65px !important;
  max-height: 100px !important;
  resize: vertical;
  overflow: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ad-input-area > textarea::-webkit-scrollbar {
  display: none;
}

.ad-input-area > input,
.ad-user-search-area input,
.ad-input-area > textarea,
.ad-input-area > select {
  background: #ffffff;
  border: 1px solid rgba(19, 15, 64, 0.2);
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 9px 15px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #130f40;
}

.ad-user-search-area input {
  height: 35px !important;
}

.ad-input-area > input::placeholder,
.ad-user-search-area input::placeholder,
.ad-input-area > textarea::placeholder {
  opacity: 0.4;
}

/* user search */
.ad-search-cont {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.1);
  position: absolute;
  top: -62px;
  border-radius: 10px;
  z-index: 99999;
  width: calc(100% - 39px);
  box-sizing: border-box;
  padding: 5px;
  z-index: 1;
}

.ad-search-cont .ad-search-input {
  border: 1px solid var(--primary-100) !important;
  width: 100%;
  height: 40px;
  margin-bottom: 5px !important;
  border-radius: 6px !important;
}

.ad-search-cont input {
  /* border: 1px solid var(--primary-100) !important; */
  outline: none;
  border: none;
  background: #fff !important;
  border-radius: 6px !important;
  color: var(--primary-700) !important;
  font-size: 14px !important;
  line-height: 21px !important;
  padding: 6px 10px !important;
  /* padding-right: 45px !important; */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
  outline: none !important;
  display: block !important;
  height: 100%;
}

.ad-search-cont .close {
  margin-right: 7px;
  width: 32.8px !important;
}

.search-result-cont {
  max-height: 510px;
  overflow: auto;
}

.ad-search-cont .search-result-cont > div {
  padding: 5px 10px;
  border-radius: 7px;
  overflow: hidden;
}

.ad-search-cont .search-result-cont > div:hover {
  background: #e8e7ec;
}

.ad-search-cont .user-row {
  padding: 10px;
  border-radius: 10px;
}

.ad-search-cont .user-row:hover {
  background: #e8e7ec;
}

.ad-search-cont .user-row.selected:hover {
  background: transparent;
}
.ad-search-cont .user-row:last-child {
  margin-bottom: 0px;
}

.ad-search-cont .user-row > div {
  width: 100%;
}

.ad-search-cont .user-updater_avatar {
  height: 50px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.ad-search-cont .user-updater_avatar img,
.ad-search-cont .user-updater_avatar > span {
  height: 100%;
  display: block;
}

.ad-search-cont .user-updater_title {
  font-weight: 500;
  font-size: 13px;
  color: #130f40;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ad-search-cont .user-updater_sub-title {
  font-weight: 500;
  font-size: 11px;
  color: #8987a0;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
