.config-branch-section .bik-table th {
  font-size: 14px;
  padding: 0 10px;
}

/* modal */
.branch-action-modal .separator-cont {
  margin-bottom: 20px;
}

.branch-action-modal .separator-title {
  font-size: 14px;
  color: var(--primary);
  white-space: nowrap;
}

.branch-action-modal hr.separator {
  width: 100%;
  margin-left: 10px;
  border: transparent;
  border-bottom: 1px solid #d0cfd9;
}

/* Responsive */
@media (max-width: 1366px) {
  .config-branch-section .bik-table th {
    font-size: 12px;
    /* padding: 0 5px; */
  }
  .config-branch-section .bik-table td {
    font-size: 12px;
    /* padding: 0 5px; */
  }
}
