.inv-config-page-cont .currency-section {
  margin-bottom: 30px;
}

.inv-config-page-cont .currency-action > div {
  font-size: 14px;
  white-space: nowrap;
  margin-left: 5px;
  border-radius: 6px;
  padding: 5px 6px;
  cursor: pointer;
}

.inv-config-page-cont .currency-action > div:nth-child(1) {
  color: #14b9ff;
  background: #dcf5ff;
}

.inv-config-page-cont .currency-action > div:nth-child(1):hover {
  color: #ffffff;
  background: #14b9ff;
}

.inv-config-page-cont .currency-action > div:nth-child(2) {
  color: #12c55c;
  background: #e8faef;
}

.inv-config-page-cont .currency-action > div:nth-child(2):hover {
  color: #ffffff;
  background: #12c55c;
}
