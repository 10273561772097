/* send sms modal */
.sms-send-cont {
  padding: 15px 25px 25px 25px;
}

.sms-send-cont textarea {
  min-height: 120px;
  max-height: 150px;
}

.sms-send-cont > div:first-child > div {
  margin-bottom: 18px;
}

.sms-send-cont > div:first-child > div:last-child {
  margin-bottom: 0;
}

.sms-send-cont > div:first-child {
  margin-bottom: 20px;
}

.sms-send-cont .action > button {
  margin-left: 10px;
}
