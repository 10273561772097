.project-dropdown-container {
  position: relative;
  min-width: 130px;
  min-height: 35px;
  width: 100%;
  height: 100%;
}

.dropdown-toggle {
  border: none;
  outline: none;
  background: #ffffff;
  border: 1px solid rgba(19, 15, 64, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 4px 10px;
  width: 100%;
  min-height: 35px;
  transition: all 0.2s;
}

.project-dropdown-container.active .dropdown-toggle {
  visibility: hidden;
}

.project-dropdown-container .project-item-cont {
  position: absolute;
  top: 40px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(19, 15, 64, 0.08);
  border-radius: 10px;
  margin: 0;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  visibility: hidden;
  max-height: 0;
  transition: all 0.2s;
}
.project-dropdown-container ul {
  margin: 0;
  padding: 0;
  max-height: auto;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  visibility: hidden;
}

.project-dropdown-container ul::-webkit-scrollbar {
  display: none;
}

.project-dropdown-container.active .project-item-cont {
  top: 0;
  max-height: 200px;
  visibility: visible;
  transition: all 0.3s;
}

.project-dropdown-container.active ul {
  overflow: auto;
  max-height: 150px;
  visibility: visible;
}

.project-dropdown-container li {
  list-style-type: none;
  padding: 4px 6px;
  box-sizing: border-box;
  border-radius: 7px;
  overflow: hidden;
  width: 100%;
  height: 35px;
  margin-bottom: 5px;
}

.project-dropdown-container li:last-child {
  margin-bottom: 0;
}

.project-dropdown-container li:hover {
  background: #fbf2fb;
}

.project-dropdown_option > div:nth-child(1) {
  width: 34px;
  height: 100%;
}

.project-dropdown_option > div:nth-child(2) {
  width: calc(100% - 55px);
  height: 100%;
}

.project-dropdown_option > div:nth-child(3) {
  width: 21px;
  height: 100%;
}

.project-dropdown_logo {
  width: 26px;
  height: 26px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.project-dropdown_tick-icon {
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.project-dropdown_logo img,
.project-dropdown_tick-icon img {
  height: 100%;
  display: block;
}

.project-dropdown_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

/* .project-dropdown-container.active .arrow {
  transform: rotate(90deg);
  transition: all 0.3s;
} */
*[role="button"] {
  cursor: pointer;
}

.dropdown-menus .manage-project-button button:first-child {
  height: 30px;
  border-radius: 6px;
}
.dropdown-menus .manage-project-button button:last-child {
  background: var(--secondary);
  height: 30px;
  width: 30px;
  border-radius: 6px;
}
