/* modal */
/* project-add */
.project-add-modal {
    padding: 20px;
}

.project-add-modal > div:first-child {
    margin-bottom: 20px;
}

.project-add-modal > div:last-child button {
    width: 110px;
}

.project-add-modal .divider {
    margin: 13px 0;
}

.project-add-modal .divider > h2 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    white-space: nowrap;
    margin: 0;
}
.project-add-modal .divider hr {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    margin: 0;
    margin-top: 5px;
}

/* project-config */

.project-setting-modal .modal-box-content {
    width: 900px;
}

.project-setting-modal .modal-content-body {
    padding: 20px;
    height: 500px;
    box-sizing: border-box;
}

.project-setting-modal .not-found img {
    margin-top: 0;
}

.project-setting-modal .modal-content-body > div:first-child {
    height: calc(100% - 80px);
    overflow: auto;
}

.project-setting-modal .modal-content-body > div:last-child {
    height: 70px;
}

.project-setting-modal .modal-content-body .modal-action > button {
    margin-left: 10px;
}
.project-sub-modal .modal-box-content {
    width: 500px;
}
.bik-table.project-manage-table > thead > tr {
    height: 40px;
    border-radius: 0;
}

.bik-table.project-manage-table > tbody > tr {
    height: 45px;
    cursor: pointer;
}

.bik-table.project-manage-table > thead > tr > th {
    border-radius: 0;
}
