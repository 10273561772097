.config-designation-section .bik-table th {
  font-size: 14px;
  padding: 0 10px;
}

.config-designation-section .bik-table span.salary-disburse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* modal */
.designation-action-modal .modal-body-cont .separator-cont {
  margin-bottom: 20px;
}

.designation-action-modal .modal-body-cont .separator-title {
  font-size: 14px;
  color: var(--primary);
  white-space: nowrap;
}

.designation-action-modal .modal-body-cont hr.separator {
  width: 100%;
  margin-left: 10px;
  border: transparent;
  border-bottom: 1px solid #d0cfd9;
}

@media (max-width: 1366px) {
  .config-designation-section .bik-table th {
    font-size: 12px;
    padding: 0 5px;
  }
  .config-designation-section .bik-table td {
    font-size: 12px;
    padding: 0 5px;
  }
}
