.page-cont.teams-page-cont {
  max-width: 1170px;
}

.page-cont.teams-page-cont .page-header-cont .action > button,
.page-cont.teams-page-cont .page-header-cont .action > div {
  margin-left: 10px;
}

.page-cont.teams-page-cont .page-header-cont .action > div:last-child {
  height: 40px;
}

.page-cont.teams-page-cont
  .page-header-cont
  .action
  > div:last-child
  > div:first-child {
  height: 100%;
}

.page-cont.teams-page-cont .page-header-cont .inst-option-cont {
  top: 59px;
  right: -10px;
}

.teams-page-cont .page-header-cont > div:nth-child(2) {
  width: 60%;
}

/* modal */
.team-action-modal-cont .calculation-text {
  font-size: 13px;
  color: var(--secondary);
  margin: 0;
  position: relative;
  top: -12px;
}
