.page-cont.inv-config-page-cont {
  max-width: 1170px;
  padding: 0;
  margin: auto;
}

.inv-config-page-cont h2.section-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary);
  margin: 0;
  margin-bottom: 10px;
}

/* .inv-config-page-cont .not-found {
  padding: 0;
} */

.inv-config-page-cont td.table-not-found-cont h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--primary);
  margin: 0;
  margin-bottom: 10px;
}

/* modal */
/* .inv-config-page-cont .config-modal-body {
  padding: 20px;
}

.inv-config-page-cont .config-modal-body > div:first-child {
  margin-bottom: 20px;
}

.inv-config-page-cont .config-modal-body .input-row {
  margin-bottom: 18px;
}
.inv-config-page-cont .config-modal-body .input-row:last-child {
  margin-bottom: 0;
}

.inv-config-page-cont .config-modal-body .action button {
  margin-left: 10px;
} */
