/* Basic */
.flex-col {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;

    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flex-col > * {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.flex-col.position-top {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.flex-col.position-middle {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.flex-col.position-bottom {
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
}
.flex-col.position-space-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.flex-col.position-space-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.flex-col.position-space-none > * {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.flex-col.align-left {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.flex-col.align-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.flex-col.align-right {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.flex-col.align-stretch {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.flex-col > .fill {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.col-gap-50 {
    column-gap: 50px;
}
.col-gap-45 {
    column-gap: 45px;
}
.col-gap-40 {
    column-gap: 40px;
}
.col-gap-35 {
    column-gap: 35px;
}
.col-gap-30 {
    column-gap: 30px;
}
.col-gap-25 {
    column-gap: 25px;
}
.col-gap-20 {
    column-gap: 20px;
}
.col-gap-15 {
    column-gap: 15px;
}
.col-gap-10 {
    column-gap: 10px;
}
.col-gap-5 {
    column-gap: 5px;
}
.col-gap-0 {
    column-gap: 0px;
}
