.info-editor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  min-width: 100px;
  height: auto;
  padding: 10px 5px 5px 5px;
  background-color: #fffbba;
  box-shadow: 0px 0px 4px 1px rgb(19 15 64 / 29%);
  border-radius: 10px;
  /* display: none; */
}

.info-editor::before {
  content: "";
  background-image: url("../../../assets/images/icons/icon-corner-v3.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 40px 45px;
  width: 40px;
  height: 42px;
  top: -24px;
  left: 15px;
}

.info-editor .btn-cancel,
.info-editor .btn-save {
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 20px;
  margin-left: 7px;
  padding: 0px 8px;
}

.info-editor .btn-cancel {
  /* background-color: var(--primary-100); */
  color: var(--primary);
}
.info-editor .btn-save {
  background-color: #00b15b;
  color: #ffffff;
}

/* text-input*/
.info-editor-input .row-1 {
  height: calc(100% - 28px);
  border-bottom: 1px solid var(--primary-200);
}

.info-editor-input .row-2 {
  height: 28px;
}

.info-editor-input input {
  display: block;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--primary-700);
  background-color: transparent;
  padding: 8px 4px;
}

/* text-input-v2 */

.info-editor.default-v2::before {
  content: "";
  display: none;
}

.info-editor.default-v2 {
  background-color: #ffffff;
  padding: 0;
  padding-right: 5px;
  border-radius: 5px;
  box-shadow: unset;
  border: 1px solid var(--primary-200);
}

.info-editor-input-v2 {
  height: 100%;
}

.info-editor-input-v2 input {
  display: block;
  width: calc(100% - 16px - 16px - 5px - 8px);
  height: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--primary-700);
  background-color: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px;
}

.info-editor-input-v2 .btn-1,
.info-editor-input-v2 .btn-2 {
  width: 16px;
  height: 16px;
}

.info-editor-input-v2 .btn-1 > img,
.info-editor-input-v2 .btn-2 > img {
  width: 100%;
  height: 100%;
}

/* input - textarea */
.info-editor-textarea {
  height: 130px;
}

.info-editor-textarea > .row-1 {
  padding: 0;
  height: calc(100% - 32px);
}

.info-editor-textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--primary-700);
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--primary-200);
  box-sizing: border-box;
  /* border-radius: 10px; */
  overflow: auto;
  padding: 8px;
}

.info-editor-textarea .row-2 {
  height: 32px;
}

/* confirm popup */
.info-editor.confirm {
  padding: 10px;
  max-width: 200px;
}

.info-editor-confirm > .row-1 {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: var();
}

.info-editor-confirm > .row-1 {
  margin-bottom: 10px;
}

.info-editor-confirm .btn-1,
.info-editor-confirm .btn-2 {
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 28px;
  margin-left: 5px;
  padding: 0px 6px;
  height: 20px;
}

.info-editor-confirm .btn-1 {
  background-color: var(--error);
  color: #ffffff;
}
.info-editor-confirm .btn-2 {
  background-color: #00b15b;
  color: #ffffff;
}
