/* Template */
.template-message {
  position: fixed;
  background: #000;
  left: 10px;
  bottom: 12px;
  width: 300px;
  border-radius: 3px;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  min-height: 50px;
  z-index: 2001;
}
.template-message > span {
  font-weight: 300;
  font-size: 14px;
}
.template-message > button {
  background: #000;
  border: 0 solid;
  color: #fff;
  cursor: pointer;
  opacity: 0.8;
}
.template-message > button:hover {
  opacity: 1;
}
.template-message > button > img {
  width: 15px;
}

.confirm-content {
  min-height: 200px;
  margin: 20px 0;
  padding: 0 50px;
}

.confirm-content > img {
  margin: 15px;
  width: 50px;
  height: 50px;
}

.confirm-content .template-clickAction button {
  margin-left: 10px;
}

/* Buttons */

.confirm-button {
  background: #4caf50;
  border: 0 solid;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  line-height: 21px;
  text-align: center;
  width: 146px;
  margin: 20px 7px;
}
.confirm-button.gray {
  background-color: gray;
}
.confirm-button.red {
  background-color: red;
}
