.gateway-trans-page * {
  box-sizing: border-box;
}

.gateway-trans-page > .inv-gateway-trans__header {
  height: 80px;
  width: 1170px;
  margin: 0 auto;
}

.inv-gateway-trans-body {
  width: 1170px;
  margin: 0 auto;
}

.inv-gateway-trans__header .user-custom-data input.custom-input {
  width: 300px;
}

.inv-gateway-trans__header .inst-option-cont {
  right: -19px;
}
