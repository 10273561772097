/* invoice title view */
.invoice .inv-title-header {
  border-radius: 7px;
  background: var(--primary-primary-100, #e8e7ec);
  width: 100%;
  margin-bottom: 15px;
  padding: 14px 16px;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.invoice .inv-title-header > h2 {
  color: var(--primary-primary, #130f40);
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoice-add-title-popup {
  padding: 8px 25px 25px 25px;
}

.invoice-add-title-popup .input-area {
  background: #ffffff;
  /* border: 1px solid rgba(19, 15, 64, 0.2); */
  border: 1px solid #d0cfd9;
  border-radius: 8px;
  padding-right: 15px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  overflow: hidden;
}

.invoice-add-title-popup .input-area p {
  background-color: rgba(19, 15, 64, 0.06);
  height: 100%;
  margin: 0;
  padding-left: 15px;
  white-space: nowrap;
}

.invoice-add-title-popup .input-area input {
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
}

.invoice-add-title-popup .input-area input,
.invoice-add-title-popup .input-area p {
  color: #130f40;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.invoice-add-title-popup > div:first-child {
  margin-bottom: 20px;
}

.invoice-add-title-popup > div:last-child button {
  margin-left: 10px;
}
