/* .tag-selector {
  margin-left: 10px;
} */

.tag-selector_item {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px;
}

.tag-selector_add-item {
  background-color: #ffffff;
  box-shadow: 2px -1px 20px 20px rgba(19, 15, 64, 0.05);
  position: relative;
}

.tag-selector_add-item:hover::after {
  content: "+";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

/* modal */
.tag-selection-modal ul {
  padding: 0;
  margin: 0;
}

.tag-selection-modal li {
  list-style-type: none;
  margin-bottom: 10px;
}

.tag-selection-modal li > div:nth-child(1) {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: 5px;
  position: relative;
}
.tag-selection-modal li > div:nth-child(2) {
  width: calc(100% - 25px);
}

.tag-selection-modal li > div:nth-child(1).active::after {
  content: "";
  position: absolute;
  right: 4px;
  top: 2px;
  width: 3px;
  height: 7px;
  border-bottom: 3px solid #ffffff;
  border-right: 3px solid #ffffff;

  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
