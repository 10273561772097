/* customer balance view */
.fund-transfer-view-cont {
  width: 100%;
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.fund-transfer-view_header {
  margin-bottom: 15px;
}

.fund-transfer-view_header > h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #130f40;
  margin: 0;
}

.fund-transfer_option-cont {
  position: relative;
}

.fund-transfer_option-cont > div:first-child {
  width: 20px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  padding: 3px;
  border-radius: 5px;
}

.fund-transfer_option-cont .inst-option-cont {
  right: -17px;
}

.fund-transfer_option-cont .inst-option-cont::before {
  right: 5px;
}

.fund-transfer-balance-info {
  margin: 10px 0;
}

.fund-transfer-balance-info .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5a577a;
  margin: 0;
}

.fund-transfer_balance-view {
  background: #fbf2fb;
  border-radius: 8px;
  width: auto;
  height: 35px;
  text-align: center;
  padding: 8px 12px;
  box-sizing: border-box;
  white-space: nowrap;
}

.fund-transfer_balance-view.op {
  margin-right: 10px;
}

.fund-transfer_balance-view > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary);
  margin: 0;
}

.fund-transfer-view_action {
  margin-left: 10px;
}

.fund-transfer_debit-btn,
.fund-transfer_credit-btn {
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
}

.fund-transfer_debit-btn {
  background: rgba(245, 3, 3, 0.1);
  border-radius: 8px 0px 0px 8px;
  color: #f50303;
}

.fund-transfer_credit-btn {
  background: rgba(18, 197, 92, 0.2);
  border-radius: 0px 8px 8px 0px;
  color: #12c55c;
  margin-left: 2px;
}

.fund-transfer_debit-btn:hover {
  background: #f50303;
  color: #ffffff;
}

.fund-transfer_credit-btn:hover {
  background: #12c55c;
  color: #ffffff;
}

/* modal */
.fund-transfer-cont .modal-box-content {
  width: 600px;
}

.fund-transfer-cont .fund-transfer-body {
  padding: 12px 25px 25px 25px;
  box-sizing: border-box;
  overflow: hidden;
}

.fund-transfer-cont .o-hide {
  box-sizing: border-box;
  overflow: hidden;
}

/* input */
.fund-transfer-cont input,
.fund-transfer-cont textarea {
  border-radius: 6px;
  border: 1px solid var(--primary-100);
  background: #fff;
  color: var(--primary-700);
  font-size: 14px;
  line-height: 21px;
  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  display: block;
}

.fund-transfer-cont input::placeholder,
.fund-transfer-cont textarea::placeholder {
  opacity: 0.6;
}

.fund-transfer-cont textarea {
  min-height: 50px;
  max-height: 80px;
  resize: vertical;
  overflow-y: auto;
  font-weight: 400;
}

.fund-transfer-cont .row-1 > div {
  width: calc((100% - 30px) / 2);
}

.fund-transfer-cont .row-1 .divider-column {
  width: 30px;
  position: relative;
}
.fund-transfer-cont .row-1 .divider-column::after {
  position: absolute;
  content: " ";
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: var(--primary-100);
}

.fund-transfer-cont .header {
  height: 30px;
}

.fund-transfer-cont .header input {
  font-size: 12px;
}

/* .fund-transfer-cont .header > div:first-child {
  width: calc(100% - 150px);
} */

/* .fund-transfer-cont .header > div:last-child {
  width: 150px;
  margin-left: 10px;
} */

.fund-transfer-cont .header_title {
  color: var(--primary);
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

/* .fund-transfer-cont .header input {
  height: 30px;
} */

.fund-transfer-cont .user-info {
  height: 55px;
}

.fund-transfer-cont .info {
  margin-bottom: 15px;
}

.fund-transfer-cont .info > div {
  margin-bottom: 15px;
}

.fund-transfer-cont .info > div:last-child {
  margin-bottom: 0;
}

.fund-transfer-cont .info span,
.fund-transfer-cont .notes-cont span {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fund-transfer-cont .info span:first-child,
.fund-transfer-cont .notes-cont span {
  color: var(--primary-700);
}

.fund-transfer-cont .info span:last-child {
  width: 130px;
  text-align: right;
}

.fund-transfer-cont .info .receiver-amount-cont {
  color: var(--secondary) !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--secondary-50) !important;
}

.fund-transfer-cont .notes-cont {
  margin-top: 10px;
  margin-bottom: 15px;
}

.fund-transfer-cont .notes-cont > div:first-child {
  width: calc(100% - 400px);
}

.fund-transfer-cont .notes-cont > div:last-child {
  width: 477px;
}

.fund-transfer-cont .action-cont button {
  margin-left: 10px;
}

/* receiver search */
.header_search-icon {
  margin-left: 10px;
}

.user-search-cont {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.1);
  width: 100%;
  max-height: 410px;
  overflow: hidden;
  position: absolute;
  top: 0;
  border-radius: 10px;
  z-index: 99999;
  padding: 5px;
}

.user-search-cont > input {
  border-radius: 6px !important;
  border: 1px solid var(--primary-100) !important;
  background: #fff !important;
  color: var(--primary-700) !important;
  font-size: 14px !important;
  line-height: 21px !important;
  padding: 6px 10px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
  outline: none !important;
  display: block !important;
  margin-bottom: 5px !important;
}

.user-search-cont .search-result-cont {
  max-height: 230px;
  overflow: auto;
}

.user-search-cont .search-result-cont > div {
  padding: 10px;
  border-radius: 7px;
  overflow: hidden;
}

.user-search-cont .search-result-cont > div:hover {
  background: #e8e7ec;
}

.user-search-cont .user-row {
  padding: 10px;
  border-radius: 10px;
}

.user-search-cont .user-row:hover {
  background: #e8e7ec;
}

.user-search-cont .user-row.selected:hover {
  background: transparent;
}
.user-search-cont .user-row:last-child {
  margin-bottom: 0px;
}

.user-search-cont .user-row > div:first-child {
  width: 35px;
}
.user-search-cont .user-row > div:last-child {
  width: calc(100% - 45px);
}

.user-search-cont .user-updater_avatar {
  height: 35px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.user-search-cont .user-updater_avatar img,
.user-search-cont .user-updater_avatar > span {
  height: 100%;
  display: block;
}

.user-search-cont .user-updater_title {
  font-weight: 500;
  font-size: 16px;
  color: #130f40;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-search-cont .user-updater_sub-title {
  font-weight: 500;
  font-size: 12px;
  color: #8987a0;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
