.adv-web-menu {
    z-index: 100;
    position: fixed;
    background: #fff;
    box-shadow: 0px 1px 0px 0px #f3f3f5;
    width: calc(100vw - 67px);
    padding: 6px 20px;
    box-sizing: border-box;
    top: 0;
    left: 67px;
    height: 48px;
}
.dropdown-menus {
    width: 200px;
}
.breadcrumb {
    margin-left: 20px;
}
.breadcrumb > div > img {
    margin: 0 10px;
}
.breadcrumb span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #5a577a;
    text-transform: capitalize;
}
.projects-select-arrow {
    order: 0;
}

/* user Setting */
.user-panel {
    gap: 16px;
}
.user-panel button {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.user-panel button > img {
    width: 100%;
}
.user-profile {
    position: relative;
    height: 36px;
}
.user-profile__btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(174, 0, 185, 0.1);
}
.user-profile__menus {
    box-sizing: border-box;
    width: 400px;
    /* padding: 8px 12px 16px; */
    position: absolute;
    right: 0px;
    top: 50px;
    background-color: #f3f6fc;
    overflow: hidden;
    border-radius: 28px;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
}

.profile-menus__outer {
    border-radius: 20px;
    padding: 15px;
    background-color: #fff;
    margin: 12px 12px 16px;
}

/* user Setting */
.account-user-img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
}
.account-user-img > img {
    width: 100%;
    object-position: center;
    object-fit: cover;
}
.account-name {
    max-width: calc(100% - 78px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}
.account-name > div {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    color: #130f40;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.account-name > .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: normal;
    color: rgba(19, 15, 64, 0.7);
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.account-manage > div {
    margin-left: 25px;
}
.account-manage > div > a {
    padding: 5px 15px;
    display: block;
    color: #130f40;
    border: 1px solid #130f40;
    border-radius: 4px;
    text-decoration: none;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
}

.account-manage > div > a:hover {
    background: rgba(31, 31, 31, 0.08);
}

.logout-div {
    margin-top: 20px;
    padding: 15px;
    padding-left: 30px;
    border-top: 1px solid rgba(19, 15, 64, 0.3);
}
.logout-div > span {
    font-size: 16px;
    line-height: 24px;
    color: #130f40;
    text-decoration: none;
    cursor: pointer;
}
