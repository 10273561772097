.invoice-product-editor {
  padding: 20px 25px 25px 25px;
}

.invoice-product-editor textarea {
  resize: vertical;
  min-height: 60px;
  max-height: 100px;
}

.invoice-product-editor .inputs-cont > div {
  margin-bottom: 18px;
  overflow: unset;
}

.invoice-product-editor .premium-contract-input,
.invoice-product-editor .custom-product-input {
  width: 100%;
}

.invoice-product-editor .premium-contract-input .ck.ck-editor__editable,
.invoice-product-editor .custom-product-input .ck.ck-editor__editable {
  max-height: 110px;
}

.invoice-product-editor .property-field {
  margin-bottom: 7px;
}

.invoice-product-editor .property-field > div:first-child {
  margin-bottom: 10px;
}

.invoice-product-editor .property-field .title {
  color: #130f40;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
  margin: 0;
}

.invoice-product-editor .property-field hr {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  margin: 0;
  margin-top: 5px;
}

.invoice-product-editor .info-text {
  font-size: 12px;
  color: #c74dce;
  white-space: nowrap;
  padding-top: 5px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
}

.invoice-product-editor .info-text.show {
  max-height: 30px;
  transition: all 0.5s;
}

/* property changer in modal */
.property-changer {
  position: relative;
  display: inline-block;
  width: 141px;
}

.property-changer .inst-option-cont {
  width: 175px;
  top: 35px;
  left: -21px;
}

.property-changer .inst-option-cont .menu-logo {
  width: 18px;
}

/* buttons */
.invoice-product-action-cont button {
  margin-left: 10px;
}
