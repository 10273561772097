.docs-page {
    padding: 50px 20px;
    box-sizing: border-box;
    min-height: calc(100vh - 50px);
}

.docs-page > * {
    box-sizing: border-box;
}

/* Docs page side menu */
.docs-menus {
    width: 340px;
    background: #f3f3f500;
    /* border: 1px solid #e5e5e5; */
    box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.05);
    border-radius: 20px;
    padding: 15px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5a577a;
}
.docs-menu-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}
.docs-menu-list ul li {
    width: 100%;
}
.docs-menu-list ul li a {
    text-decoration: none;
    color: #5a577a;
    display: block;
    width: 100%;
    padding: 8px 15px;
    border-radius: 8px;
    box-sizing: border-box;
}
.docs-menu-list ul li a:hover,
.docs-menu-list ul li a.active {
    background: #fbf2fb;
}
/* Common Class */
.inner-scroll {
    overflow: auto;
    height: 100%;
}
/* Docs page container */
.docs-cont {
    background: #f3f3f500;
    min-height: 50vh;
    width: 1200px;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.05);
    border-radius: 20px;
}

/* Cover Page Design */
.cover-page {
    box-sizing: border-box;
}
/* .objects-create */
.objects-create-data {
    padding-left: 40px;
}
/* Modules */
.modules-title {
    font-size: 18px;
}

/* Menu Configuration */
.menu-config * {
    box-sizing: border-box;
}

.sub-menu-item {
    padding-left: 20px;
}

/* Page view design */

.doc-page-view * {
    box-sizing: border-box;
}
.doc-view__page-content .bik-table-parent {
    min-height: 140px;
}
.doc-view__page-content .bik-table > tbody > tr > td {
    vertical-align: baseline;
}
/* View Page inner List */
.table-custom-list {
    padding-left: 10px;
    margin: 0;
    width: 100%;
}
.table-custom-list > li {
    padding: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #5a577a;
}
.table-add-btn {
    outline: none;
    border: none;
    background: rgba(18, 197, 92, 0.2);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #12c55c;
    padding: 4px 6px;
    margin: 5px 0;
    cursor: pointer;
    /* border: 1px solid var(--primary-100); */
    border-radius: 6px;
    transition: all 0.1s ease-in-out;
}
.table-add-btn:hover {
    background: #12c55c;
    color: #fff;
}

/* table-add-task-box */
.table-add-task-box {
    border: 1px solid var(--primary-100);
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: var(--primary-500);
    padding: 5px 12px;
    width: 100%;
    resize: none;
}
