/* Modal */

.modal-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #292929d4;
  z-index: 2000;
  left: 0;
  top: 0;
}

.modal-box .modal-box-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  position: fixed;
  background: #fff;
  left: 50%;
  top: 50%;
  width: 490px;
  min-height: 185px;
  transform: translate(-50%, -50%) !important;
  max-height: calc(100% - 20px);
  max-width: calc(100% - 50px);
  overflow: hidden;
  border-radius: 25px;
}

/* Title Start */
.modal-box .modal-box-header {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  height: 70px;
  border-bottom: 1px solid rgba(19, 15, 64, 0.1);
}

.modal-box .modal-box-header > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.modal-box .modal-box-title {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  font-size: 20px;
  line-height: 35px;
  color: #130f40;
  margin-left: 25px;
  text-align: left;
}
.modal-box .modal-box-title span {
  display: block;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.7;
}

.modal-box .modal-box-title.sub-title {
  line-height: unset;
}

.modal-box .modal-box-title small {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.modal-box .modal-box-close {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  margin-right: 25px;
}

.modal-box .modal-box-close > button {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

/* Title End */

/* Body Start */
.modal-box .modal-box-body {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  overflow-y: auto;
  overflow-x: none;
  /* border-top: 1px solid rgba(19, 15, 64, 0.1); */
}

.modal-box .modal-box-body::-webkit-scrollbar {
  display: none;
}

.modal-box .modal-box-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal-box .modal-box-body > div {
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
}

.modal-box .modal-box-footer {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  height: 30px;
}

.modal-body-cont {
  padding: 20px;
}

.modal-body-cont .modal-action {
  margin-top: 20px;
}

.modal-body-cont .modal-action button {
  margin-left: 10px;
}

.modal-body-cont .input-row {
  margin-bottom: 18px;
  box-sizing: border-box;
}

.modal-body-cont .input-row:last-child {
  margin-bottom: 0px;
}

/* Body End */

/* Buttons */
.modal-box button.close {
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url("https://files.bikiran.com/assets/images/btn-close.svg");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  border: 1px solid rgba(255, 27, 40, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-box button.close:hover {
  background: #ff1b28;
  background-image: url("https://files.bikiran.com/assets/images/icon/button-close-whiteV1.svg");
  background-repeat: no-repeat;
  background-position: center;
}
