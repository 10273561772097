/* send push modal */
.sms-push-cont {
  padding: 15px 25px 25px 25px;
}

.sms-push-cont textarea {
  min-height: 90px;
}

.sms-push-cont > div:first-child > div {
  margin-bottom: 18px;
}

.sms-push-cont > div:first-child > div:last-child {
  margin-bottom: 0;
}

.sms-push-cont > div:first-child {
  margin-bottom: 20px;
}

.sms-push-cont .action > button {
  margin-left: 10px;
}
