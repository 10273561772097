/* common */
.inp-fill {
  width: calc(((100% - 5px) / 3) * 2);
}

.inp-fill-3 {
  width: calc((100% - 20px) / 3);
}

.inp-fill-2 {
  width: calc((100% - 10px) / 2);
}

.input-area > div {
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 6px;
}

.input-area > div:last-child {
  margin-bottom: 0px;
}

input.form-input,
textarea.form-input,
select.form-input {
  background: #ffffff;
  /* border: 1px solid rgba(19, 15, 64, 0.2); */
  border: 1px solid #d0cfd9;
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 9px 15px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #130f40;
  display: block;
}

textarea.form-input {
  overflow: auto;
}

textarea.form-input::-webkit-scrollbar {
  display: none;
}

/* auto-fill text color and behavior */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* read only input field background color */
/* input.form-input:read-only, */
input:disabled,
select:disabled,
/* input:not(.flatpickr-input), */
textarea.form-input:disabled {
  background-color: rgba(19, 15, 64, 0.06) !important;
}

select:disabled {
  position: relative;
  z-index: -1;
}

input.form-input::placeholder,
textarea.form-input::placeholder {
  color: #b9b7c6;
}

/* select tag bottom arrow as background image */
select {
  overflow: hidden !important;
  box-sizing: border-box !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 26px !important;
  background-image: url("../../images/icons/icon-down-arrow-v2.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center right 10px !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

/* label without input */
label.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  margin-left: 2px;
  color: #130f40;
}

.flatpickr-input {
  background-color: #ffffff !important;
}

/* animate input field start */

.animate-input-area {
  position: relative;
  width: 100%;
}

.animate-input-area .label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5a577a;
  position: absolute;
  background: #fff;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.animate-input-area .label span {
  color: #f50303;
}

/* default value on inpu field */
.animate-input-area.phone input {
  padding-left: 5px !important;
}
.animate-input-area.phone .default-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary);
  /* background-color: var(--primary-200); */
  display: block;
  padding-left: 10px;
}

/* input */
.animate-input-area .inp-cont > input {
  border: none;
}

.inp-cont {
  border: 1px solid #d0cfd9;
  border-radius: 8px;
  padding-right: 10px;
}

.animate-input-area input,
.animate-input-area select {
  color: #130f4000 !important;
  padding: 10px 7px 10px 10px !important;
}

.animate-input-area select {
  padding-bottom: 8px !important;
}

.animate-input-area textarea {
  resize: vertical;
  min-height: 70px;
  max-height: 120px;
}

/* has value on input field */
.animate-input-area.has-value .label,
.animate-input-area .label.active {
  color: #8987a0;
  top: 0px;
  left: 15px;
  padding: 0 5px;
  transition: all 0.4s;
}

.animate-input-area.has-value input,
.animate-input-area.has-value select {
  color: #130f40 !important;
}

.animate-input-area.has-value input:-webkit-autofill {
  transition: unset !important;
}

/* on focused */
.animate-input-area.has-value.focused label {
  color: #ae00b9;
}

/* disabled */
.animate-input-area.disabled .label {
  background: #f1f1f4 !important;
  padding-top: 3px !important;
  border-radius: 5px !important;
  color: #8987a0e6 !important;
}

.animate-input-area.disabled .inp-cont {
  padding-right: 0 !important;
}

.animate-input-area.has-value.focused .inp-cont,
.animate-input-area.has-value.focused .flatpickr-input,
.animate-input-area.has-value.focused select,
.animate-input-area.has-value.focused textarea {
  border: 1px solid #d780dc !important;
}

/* input info icon */
.animate-input_info {
  visibility: hidden;
}

.animate-input_info img {
  width: 16px;
  margin-left: 2px;
  cursor: pointer;
  display: block;
}

.animate-input-area.has-value .animate-input_info {
  visibility: visible;
}

/* input unit */
.animate-input_unit {
  font-size: 10px;
  color: #8987a0;
  display: none;
}

.animate-input-area.has-value .animate-input_unit {
  display: block;
}

/* animate input field end */

/* radio button */
.input-radio [type="radio"]:checked,
.input-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.input-radio [type="radio"]:checked + label,
.input-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  /* display: inline-block; */
  color: #666;
}
.input-radio [type="radio"]:checked + label:before,
.input-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #f50057;
  border-radius: 100%;
}

.input-radio.blue-variant [type="radio"]:checked + label:before,
.input-radio.blue-variant [type="radio"]:not(:checked) + label:before {
  border: 1px solid #130f40;
}

.input-radio.blue-variant [type="radio"]:checked + label:after,
.input-radio.blue-variant [type="radio"]:not(:checked) + label:after {
  background: #130f40;
}

.input-radio [type="radio"]:checked + label:after,
.input-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #f50057;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.input-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.input-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* radio button */

/* Checkbox */
.input-checkbox [type="radio"]:checked,
.input-checkbox [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.input-checkbox [type="radio"]:checked + label,
.input-checkbox [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  /* display: inline-block; */
  color: #666;
}
.input-checkbox [type="radio"]:checked + label:before,
.input-checkbox [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 18px;
  height: 18px;
  border: 1px solid #f50057;
  border-radius: 5px;
}

.input-checkbox.blue-variant [type="radio"]:checked + label:before,
.input-checkbox.blue-variant [type="radio"]:not(:checked) + label:before {
  border: 1px solid #130f40;
}

.input-checkbox.blue-variant [type="radio"]:checked + label:after,
.input-checkbox.blue-variant [type="radio"]:not(:checked) + label:after {
  background: #130f40;
}

.input-checkbox [type="radio"]:checked + label:after,
.input-checkbox [type="radio"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  right: 6px;
  top: 4px;
  width: 4px;
  height: 8px;
  border-bottom: 3px solid #f50057;
  border-right: 3px solid #f50057;

  transition: all 0.2s ease;
}
.input-checkbox [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.input-checkbox [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
/* Checkbox */
