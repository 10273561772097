/* useful buttons */
.invoice-info-cont.buttons {
  overflow: unset;
}

.invoice-info_buttons-cont {
  margin: -5px;
}

.invoice-info_buttons-cont > div {
  flex: 0 1 auto;
  box-sizing: border-box;
  padding: 5px;
}
