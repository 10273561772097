.bik-table-parent {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.05);
  border-radius: 20px;
  box-sizing: border-box;
  padding-bottom: 15px;
  width: 100%;
}
.bik-table * {
  box-sizing: border-box;
}
.bik-table,
.bik-table tr,
.bik-table td {
  border: none;
  border-collapse: collapse;
}

.bik-table {
  width: 100%;
  /* box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.05); */
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  table-layout: fixed;
  /* border-radius: 20px; */
}
/* Table Header */

.bik-table > thead > tr {
  background: #e8e7ec;
  height: 60px;
}
.bik-table > thead > tr > th {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #130f40;
  padding: 0 12px;
}
.bik-table > thead > tr > th.text-align-right {
  text-align: right;
}
.bik-table > thead > tr > th.text-align-center {
  text-align: center;
}
.bik-table > thead > tr > th.text-align-left {
  text-align: left;
}
.bik-table > thead > tr > th:first-of-type {
  border-top-left-radius: 20px;
  padding-left: 20px;
}
.bik-table > thead > tr > th:last-of-type {
  padding-right: 20px;
}
.bik-table > thead > tr > th:last-of-type {
  border-top-right-radius: 20px;
}
.bik-table > thead > tr > th::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background: rgb(19, 15, 64, 0.15);
}
.bik-table > thead > tr > th:last-of-type::after {
  display: none;
}
/* Table Body */
.bik-table > tbody > tr {
  height: 50px;
  border-bottom: 1px solid rgba(28, 38, 73, 0.2);
}
.bik-table > tbody > tr > td {
  padding: 0 14px;
}
.bik-table > tbody > tr > td:first-of-type {
  padding-left: 20px;
}
.bik-table > tbody > tr > td:last-of-type {
  padding-right: 20px;
}
.bik-table > tbody > tr > td span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-700);
}
.bik-table > tbody > tr:first-of-type {
  border-top: 1px solid #ffffff00;
}
.bik-table > tbody > tr.active:first-of-type {
  border-top: 1px solid rgba(28, 38, 73, 0.2);
}
.bik-table > tbody > tr > td .action > span button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.bik-table > tbody > tr.previous {
  border-bottom: 1px solid #ffffff00;
}
.bik-table > tbody > tr.active {
  background: #e8e7ec;
  border-bottom: 1px solid #ffffff00;
}
.bik-table > tbody > tr.isChecked:first-of-type {
  border-top: 1px solid rgba(28, 38, 73, 0.2);
}
.bik-table > tbody > tr.isChecked {
  background: #e8e7ec;
  border-top: 1px solid rgba(28, 38, 73, 0.2);
  border-bottom: 1px solid rgba(28, 38, 73, 0.2);
}
.bik-table > tbody > tr.activeClick {
  background: #d0cfd9;
}

/* TODO: Remove this css after all table fixed this class */
.bik-table > tbody > tr > td .status.active span,
.bik-table > tbody > tr > td .status.success span {
  color: #00b15b;
  text-transform: capitalize;
}
.bik-table > tbody > tr > td .status.pending span {
  color: #f1c40f;
  text-transform: capitalize;
}

/* updated this */
.bik-table > tbody > tr > td span.status.active,
.bik-table > tbody > tr > td span.status.success {
  color: #00b15b;
  text-transform: capitalize;
}
.bik-table > tbody > tr > td span.status.inactive,
.bik-table > tbody > tr > td span.status.pending {
  color: #f1c40f;
  text-transform: capitalize;
}
.bik-table > tbody > tr > td span.status.cancel {
  color: var(--error);
  text-transform: capitalize;
}

/*-- If input box available */
.bik-table > thead > tr > th input[type="checkbox"],
.bik-table > tbody > tr > td input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.bik-table > tbody > tr > td .started-btn {
  width: 18px;
  height: 18px;
  display: block;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  padding-bottom: 2px;
  margin: 0;
  cursor: pointer;
}
.bik-table > tbody > tr > td .started-btn > img {
  width: 100%;
  display: block;
  object-position: center;
  object-fit: cover;
}

/* .bik-table > tbody > tr:first-of-type:hover, */
.bik-table > tbody > tr.active:hover,
.bik-table > tbody > tr.previous:hover,
.bik-table > tbody > tr:hover {
  background: var(--primary-200);
  border-top: 1px solid rgba(28, 38, 73, 0.2) !important;
  border-bottom: 1px solid rgba(28, 38, 73, 0.2) !important;
}
