.feature-trans-summary * {
    box-sizing: border-box;
}
.feature-trans-summary {
    position: fixed;
    right: -290px;
    top: 170px;
    width: 350px;
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.08);
    transition: all 0.5s ease-in-out;
    border-radius: 15px 0 0 15px;
    opacity: 95%;
}
.feature-trans-summary.active {
    right: 60px;
}
.feature-trans-body__header {
    padding: 15px;
    height: 60px;
    border-bottom: 1px solid rgba(19, 15, 64, 0.1);
}
.feature-trans-body__header > span {
    font-size: 20px;
    line-height: 30px;
    color: var(--primary);
    font-weight: 500;
}
.feature-trans-body__cont {
    padding: 15px;
}
.feature-trans-body__cont > div > div {
    width: 100%;
}
.feature-trans-body__cont > div > div:last-child {
    border-top: 1px solid rgba(19, 15, 64, 0.2);
    padding-top: 8px;
}
.feature-trans-body__cont span,
.feature-trans-body__cont div {
    font-size: 16px;
    line-height: 25px;
    color: var(--primary-700);
}

.feature-summary-open-btn {
    display: block;
    opacity: 1;
    cursor: pointer;
    position: absolute;
    left: -40px;
    top: 0;
    transform: rotate(90deg);
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    background-position: center;
    background-size: 25px;
    background-image: url("./../../images/icons/icon-down-arrow-v3.svg");
    background-color: var(--secondary);
    background-repeat: no-repeat;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 3px 1px 20px -1px rgba(19, 15, 64, 0.4);
}
.feature-trans-summary .feature-summary-open-btn.hide {
    opacity: 0;
}
.feature-summary-hide-btn {
    width: 30px;
    height: 30px;
    background-color: transparent;
    background-image: url("https://files.bikiran.com/assets/images/btn-close.svg");
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    border: 1px solid rgba(255, 27, 40, 0.1);
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}
.feature-summary-hide-btn:hover {
    background: #ff1b28;
    background-size: 8px;
    background-image: url("https://files.bikiran.com/assets/images/icon/button-close-whiteV1.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.feature-trans-body__cont .feature-no-currency {
    font-size: 24px;
    line-height: 36px;
    padding: 25px;
    height: 150px;
    color: var(--error);
    text-align: center;
}
