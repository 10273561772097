.invoice-duplicate-popup {
  padding: 8px 25px 25px 25px;
}

.invoice-duplicate-popup > div:first-child {
  margin-bottom: 20px;
}

.invoice-duplicate_action > button {
  margin-left: 10px;
}
