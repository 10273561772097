.user-updater .modal-box .modal-box-content,
.user-updater .modal-box .modal-box-body {
  overflow: unset;
}

.user-updater-container {
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;
  min-height: 320px;
}

.user-updater-container .hide {
  max-height: 0;
  overflow: hidden;
  /* transition: all 0.5s; */
}

.user-updater-container .show {
  max-height: 400px;
  /* transition: all 0.4s; */
}

.user-updater-body {
  box-sizing: border-box;
  overflow: auto;
  margin-bottom: 10px;
  transition: all 0.5s;
}

.user-row {
  padding: 10px;
  border-radius: 10px;
}

.user-row:hover {
  background: #e8e7ec;
}

.user-row.selected:hover {
  background: transparent;
}
.user-row:last-child {
  margin-bottom: 0px;
}

.user-row > div:first-child {
  width: 50px;
}
.user-row > div:last-child {
  width: calc(100% - 65px);
}

.updater-search-area {
  position: relative;
  height: 75px;
  margin-bottom: 10px;
}

.updater-search-area > div {
  position: absolute;
  top: 0;
  border-radius: 10px;
  z-index: 99999;
  width: 100%;
  padding: 5px;
}

.updater-search-area.active > div {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(19, 15, 64, 0.1);
}

.searched-user-container {
  transition: all 0.5s;
  border-top: 0;
  box-sizing: border-box;
  max-height: 297px;
  overflow: auto;
  margin-top: 5px;
}

.user-updater_title {
  font-weight: 500;
  font-size: 22px;
  /* line-height: 27px; */
  color: #130f40;
  margin: 0;
}

.user-updater_sub-title {
  font-weight: 500;
  font-size: 14px;
  /* line-height: 17px; */
  color: #8987a0;
  margin: 0;
}

.user-updater_tag {
  padding: 2px 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: #e8faef;
  border-radius: 10px;
  color: #17dc69;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.user-updater_tag.new {
  background-color: #fee6e6;
  color: #f50303;
}

.user-updater_avatar {
  height: 50px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.user-updater_avatar img,
.user-updater_avatar > span {
  height: 100%;
  display: block;
}

/* serach */
.user-input-area {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  /* position: relative; */
}

.user-input-area > label,
.updater-search-area label {
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  margin-left: 2px;
  color: #130f40;
}

.user-input-area > input,
.user-input-area > select,
.updater-search-area input {
  background: #ffffff;
  border: 1px solid rgba(19, 15, 64, 0.2);
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 9px 15px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #130f40;
}

.user-input-area > input::placeholder,
.user-input-area > textarea::placeholder,
.updater-search-area input::placeholder {
  opacity: 0.4;
}

/* button */

.disabled-view {
  filter: grayscale(100%);
  cursor: default;
  pointer-events: none;
}

button.user-action-button {
  border: unset;
  outline: unset;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  box-sizing: border-box;
  width: 120px;
}

button.button-save {
  background: #17dc69;
  color: #fff;
  padding: 9px 0px;
  border-radius: 8px;
  margin-left: 10px;
}

button.button-create {
  background: rgba(20, 185, 255, 0.15);
  color: #14b9ff;
  padding: 9px 0px;
  border-radius: 8px;
}

button.button-cancel {
  background: #f3f3f5;
  color: #8987a0;
  padding: 9px 0px;
  border-radius: 8px;
}

/* placeholder */
.p-mode {
  filter: grayscale(1);
}

.p-mode:hover {
  background-color: transparent !important;
}

.p-mode * {
  cursor: default;
  pointer-events: none;
}

.p-mode span,
.p-mode a {
  color: transparent !important;
  background: #999;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  border-radius: 10px;
  opacity: 1 !important;
}

.p-mode button,
.p-mode input[type="checkbox"] {
  visibility: hidden;
}

.p-mode span {
  line-height: 18px !important;
  font-size: 13px;
}
.p-mode span * {
  color: transparent !important;
}
.p-mode span img {
  visibility: hidden;
}
