.app-manage-section {
  width: 100%;
  height: 100%;
}

.app-manage-section > div {
  width: 50%;
  height: 50%;
  background: #9999990f;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
}

.app-manage-section > div > p:nth-child(1) {
  font-size: 20px;
  color: var(--primary);
  margin: 0;
}

.app-manage-section > div > p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  /* margin: 5px 0; */
}

.app-manage-section > div > p:nth-child(2) > input {
  margin-right: 5px;
}

.app-manage-section > div > p:nth-child(2) > span {
  color: var(--secondary);
  margin-left: 3px;
}

.app-manage-section .bik-logo {
  width: 70px;
}

.app-manage-section .bik-logo > img {
  width: 100%;
  display: block;
}
